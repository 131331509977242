import { useTranslations } from "../../contexts/Translation";
import { H1 } from "../Headers";
import Section from "../Section";
import { SubscriptionDetails } from "../Settings/SubscriptionDetails";
import { InfoMessageBox } from "../InfoMessage/InfoMessageBox";

export const SubscriptionActivated = () => {
  const t = useTranslations('subscription');
  const isSuccess = window.location.search.includes('success=true');
  return (
    <Section>
      <H1>{t('subscriptionActivatedHeading')}</H1>
      <InfoMessageBox body={isSuccess ? t('subscriptionActivatedSuccess') : t('subscriptionActivationError')} />
      <SubscriptionDetails />
    </Section>
  );
};
