import GroupSelector from "./UserManagement/GroupSelector";
import { UserManagementProvider, useUserManagement } from "./UserManagement/UserManagementContext";
import { Group } from "../../types";

type ComponentProps = {
  setGroupsWithAccess: (groups: string[]) => void;
  initiallySelected: string[];
  availableGroups?: string[];
};

const GroupAccessComponent = ({ setGroupsWithAccess, initiallySelected, availableGroups }: ComponentProps) => {
  const { groups } = useUserManagement();

  if (groups.length === 0) {
    return null;
  }

  const changeGroups = (selected: Group[]) => {
    setGroupsWithAccess(selected.map(g => g.id));
  };

  const groupList = availableGroups ? groups.filter(g => availableGroups.includes(g.id)) : groups;
  return (
    <GroupSelector groups={groupList} selected={initiallySelected.map(g => groups.find(gr => gr.id === g)).filter(g => !!g) as Group[] || []} onChange={changeGroups} />
  );
}

const GroupAccess = ({ setGroupsWithAccess, initiallySelected, availableGroups }: ComponentProps) => {
  return (
    <UserManagementProvider>
      <GroupAccessComponent availableGroups={availableGroups} initiallySelected={initiallySelected} setGroupsWithAccess={setGroupsWithAccess} />
    </UserManagementProvider>
  );
}

export default GroupAccess;