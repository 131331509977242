import { useTranslations } from "../../contexts/Translation";
import { H1 } from "../Headers";
import Section from "../Section";
import HorizontalNavigation from "../HorizontalNavigation/Index";
import ProfileForm from "../Forms/ProfileForm";
import OrganizationForm from "../Forms/OrganizationForm";
import { useOrganizations } from "../../contexts/UserProvider";
import { useEffect, useState } from "react";
import { useApiData } from "../../contexts/ApiData";
import SystemMessage from '../SystemMessage/Index';
import { Profile } from "../../types";
import UserManagement from "../Forms/UserManagement/UserManagement";
import { SubscriptionDetails } from "./SubscriptionDetails";

const Settings = () => {
  const t = useTranslations('settings');
  const [ profile, setProfile ] = useState<Profile | null>(null);
  const { currentOrganization } = useOrganizations();
  const { get } = useApiData();
  const [ isLoading, setIsLoading ] = useState(true);

  const startPage = window.location.hash === '#subscription' ? 3 : 0;
  
  useEffect(() => {
    const call = async () => {
      const response = await get('/profile');
      setProfile(response);
      setIsLoading(false);
    }
    call();
  }, [get]);

  if (isLoading || !profile) {
    return <SystemMessage message="loading" />
  }
  const pages = [<ProfileForm profile={profile} isEdit />];
  const currentOrg = profile.roles.find(o => o.orgId === currentOrganization);
  if (currentOrg?.role === 'owner') {
    pages.push(<OrganizationForm isEdit />);
    pages.push(<UserManagement />);
    pages.push(<SubscriptionDetails />);
  }
  const labels = [t('profile'), t('organization'), t('members'), t('subscription.settingsHeading')];
  return (
    <Section>
      <H1 sx={{ mb: 16 }}>{t('heading')}</H1>
      <HorizontalNavigation start={startPage} labels={labels} pages={pages} />
    </Section>
  );
};

export default Settings;
