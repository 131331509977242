import { Input } from "theme-ui";
import { useTranslations } from "../../contexts/Translation";
import { useState } from "react";
import { IconButton } from "../Button";
import { Copy } from "../Icons";

const CopyValue = ({ value, variant = "input" }: { value: string; variant?: "text" | "input" }) => {
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState(false);
  const t = useTranslations('misc');
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
    } catch (error) {
      setError(true);
    }
  };
  return (
    <>
      { variant === "input" ?
        <Input sx={{ p: 8, width: '85%', display: 'inline' }} type="text" value={value} readOnly />
        : <span>{value}</span>
      }
      <IconButton onClick={() => copyToClipboard(value)} title={t('copy')}><Copy height={variant === "input" ? '30px': '20px'} width={variant === "input" ? '30px': '20px'} /></IconButton>
      {copied && <span>{t('copiedToClipboard')}</span>}
      {error && <span>{t('errorCopying')}</span>}
    </>
  );
};


export default CopyValue;