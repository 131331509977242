import { Box } from "theme-ui";
import { BaseThemeCompProp } from "../../types";

const Main = ({ children, sx }: BaseThemeCompProp) => (
  <Box as="main" sx={sx}>
    {children}
  </Box>
);

export default Main;
