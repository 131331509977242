
import { ValidationError, WithSx } from '../../types';
import { Box } from 'theme-ui';

type ErrorTextProps = WithSx & {
  error?: ValidationError | null;
  text?: string;
}

const ErrorText = ({ error, text, sx }: ErrorTextProps) => {
  if (!error && !text) {
    return null;
  }
  return <Box sx={{ color: 'error', ...sx }} as="p">{text || error?.message}</Box>
};

export default ErrorText;
