import { Label, Select, ThemeUIStyleObject } from 'theme-ui';
import { useLocale, useSetLocale } from '../../contexts/Translation';
import { setCookie } from '../../lib/cookie';
import { WithInputProps } from '../../types';

type LanguageSelectorProps = WithInputProps & {
  handleChange?: React.ChangeEventHandler | null,
  lsx?: ThemeUIStyleObject | undefined,
};

const LanguageSelector = ({ handleChange, sx, label, name, value, lsx }: LanguageSelectorProps) => {
  const { setLocale } = useSetLocale();
  const locale = useLocale();

  const changeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const locale = e.target.value;
    setCookie('LANG', locale);
    setLocale(locale);
  };

  return (
    <>
      { label && <Label sx={{ pb: 8, ...lsx }} htmlFor={name}>{label}</Label> }
      <Select
        name={name}
        id={name}
        sx={{ width: 180, p: 12, borderRadius: 8, ...sx }}
        onChange={handleChange || changeLanguage}
        defaultValue={value ? `${value}` : locale}>
        <option value="en">English</option>
        <option value="no">Norsk</option>
      </Select>
    </>
  );
};

export default LanguageSelector;
