import { WithChildren } from "../../types";
import { Box } from "theme-ui";
import { LightBulb } from "../Icons";

type ComponentProps = WithChildren & {
  type?: 'info' | 'warning';
}

export const Tip = ({ children, type = 'warning' }: ComponentProps) => {
  return (
<Box as="p" sx={{ display: 'flex', alignItems: 'center', gap: '10px', m: 16, p: 16, border: '1px solid #441144', borderColor: 'mainText', backgroundColor: 'info' }}>
  <Box sx={{ width: 200 }}><LightBulb width="50px" height="50px" /></Box>
  {children}
</Box>
  );
};
