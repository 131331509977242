import { H2 } from "../Headers";
import ImprovementGoalForm from "../Forms/ImprovementGoalForm";
import { Box } from "theme-ui";
import QuestionScores from "./QuestionScores";
import { ImprovementData, ImprovementGoal, OrgGoal } from "../../types";
import { useTranslations } from "../../contexts/Translation";
import OrgGoalForm from "../Forms/OrgGoalForm";

export const InitialPhaseComponent = ({ isEdit, goal, improvementData, isOrgGoal = false, questions }: { isEdit: boolean; goal: ImprovementGoal | OrgGoal; improvementData?: ImprovementData | null | undefined, isOrgGoal?: boolean; questions: string[]} ) => {
  const t = useTranslations('improvementGoals');
  const categories = goal.categories.filter(g => !!g);

  return (
    <Box>
      { isEdit ? (isOrgGoal ? <OrgGoalForm isEdit /> : <ImprovementGoalForm isEdit />)
      : (<>
        <H2>{t('goalDescriptionHeading')}</H2>
        <Box as="p" sx={{ whiteSpace: 'pre-wrap', my: 16 }}>{goal.goal}</Box>
        <Box as="p"><strong>{t('improvementCategories')}: </strong>{ categories.length > 0 ? categories.map(c => t( c !== 'knowledgeSharing' ? `questions.${c}Label` : `${c}LabelLong`)).join(', '): t('noSelectedCategories')}</Box>
        <H2>{t('initialSituationHeading')}</H2>
        <Box as="p" sx={{ whiteSpace: 'pre-wrap', my: 16 }}>{goal.situation}</Box>

        { improvementData && <QuestionScores questions={questions} scores={improvementData} selected={goal.categories} /> }
        { isOrgGoal && <QuestionScores questions={questions} scores={goal.originalScores} selected={goal.categories} /> }
        <H2>{t('planHeading')}</H2>
        <Box as="p" sx={{ whiteSpace: 'pre-wrap', my: 16 }}>{goal.plan}</Box>
      </>)}
    </Box>
  );
}
