
export const getCookie = name => {
  const regex = new RegExp(`(^|; )${name}=([^;]+)`)
  return document.cookie.match(regex)?.[2];
};

export const setCookie = (name, value, path = '/', expires = false) => {
  let expDate;
  if (!expires) {
    const expDateObj = new Date();
    expDateObj.setFullYear(expDateObj.getFullYear() + 1);
    expDate = expDateObj.toUTCString();
  } else if (typeof expires === 'string') {
    expDate = expires;
  } else {
    expDate = expires.toUTCString();
  }
  document.cookie = `${name}=${value}; expires=${expDate}; path=${path}`;
}
