
import { Box } from 'theme-ui';
import { useTranslations } from '../../contexts/Translation';
import { H2 } from '../Headers';
import { useState } from 'react';
import { useApiData } from '../../contexts/ApiData';
import Button from '../Button';
import Input, { RadioGroup } from './Input';
import { ConclusionData, OrgGoal, ValidationError } from '../../types';
import ErrorText from './ErrorText';
import { useImprovementGoal } from '../../contexts/ImprovementGoal';

const GoalConclusionForm = ({ orgGoal }: { orgGoal?: OrgGoal | null}) => {
  const t = useTranslations('improvementGoals');
  const { goal } = useImprovementGoal();
  const { patch } = useApiData();
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const initialData: ConclusionData = {
     id: orgGoal?.id || goal?.id || '',
     result: orgGoal?.result || goal?.result || '',
     isSuccess: orgGoal?.isSuccess || goal?.isSuccess || '',
  };
  const [formData, setFormData] = useState<ConclusionData>(initialData);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    setErrors(errors.filter(err => err.name !== e.target.name));
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const result = await patch(orgGoal ? `/organization-goal/${formData.id}` : `/improvement-goal/${formData.id}`, formData);
    if (!result) {
      setErrors([{ name: 'form', message: t('serverError') }]);
    } else {
      window.location.reload();
    }
  };

  return (
    <Box>
      <H2>{t('conclusionHeading')}</H2>
      <Box as="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
      
        { errors.filter(e => e.name !== 'form').length > 0 && <ErrorText sx={{ mt: 32, fontSize: 24, }} text={t('formErrors')} /> }
        <ErrorText sx={{ mt: 32, fontSize: 24, }} error={errors.find(e => e.name === 'form')} />

        <RadioGroup
          name="isSuccess"
          handleChange={handleChange}
          label={t('isSuccessLabel')}
          helpText={t('help.goalIsSuccessHelp')}
          value={formData.isSuccess}
          options={[{ value: 'yes', text: t('isSuccessYes') }, { value: 'no', text: t('isSuccessNo')}]}
        />

        <Input
          type="textarea"
          name="result"
          helpText={t('help.goalResult')}
          handleChange={handleChange}
          label={t('result')}
          value={formData.result}
        />

        <Button>{t('submitConclusion')}</Button>
      </Box>
    </Box>
  );
};

export default GoalConclusionForm;
