import { useEffect, useState } from "react";
import { Recommendation as RecommendationType } from "../../types";
import { useApiData } from "../../contexts/ApiData";
import Quote from "../Quote";
import { getQuoteName } from "../../lib/getQuoteName";
import Section from "../Section";

const Recommendations = () => {
  const { get } = useApiData();
  const [ recommendations, setRecommendations ] = useState<RecommendationType[]>([]);
  useEffect(() => {
    const call = async () => {
      const recs = await get(`/recommendations`);
      if (recs) {
        setRecommendations(recs);
      }
    };
    call();
  }, [get]);

  if (recommendations.length === 0) {
    return null;
  }

  return (
    <Section>
      { recommendations.map(r => (
        <Quote date={r.respondedAt} from={getQuoteName(r.recommendationName, r.recommendationTitle, r.email)}>{r.recommendation}</Quote>
      ))}
    </Section>
  );
};

export default Recommendations;
