import Main from './components/Main';
import { Box } from 'theme-ui';
import Logo from './components/Icons/Logo';
import { Card } from './types';
import { useEffect, useState } from 'react';
import { useApiData } from './contexts/ApiData';
import SystemMessage from './components/SystemMessage/Index';
import FeedbachCard from './components/FeedbachCard/Index';
import Section from './components/Section';

const FeedbachCardApp = () => {
  const { get } = useApiData();
  const [ card, setCard ] = useState<Card>();
  const id = window.location.pathname.split('/')?.[2];
  const [ notFound, setNotFound ] = useState<boolean>(false);

  useEffect(() => {
    const call = async () => {
      const cardResult = await get(`/c/${id}`);
      if (cardResult) {
        setCard(cardResult);
      } else {
        setNotFound(true);
      }
    }
    call();
  }, [get, id]);

  if (notFound) {
    return <SystemMessage isError message="notFound" />
  }

  if (!card) {
    return <SystemMessage message="loading" />
  }

  return (
    <Main>
      <Box sx={{
        background: 'responderPrimary',
        my: 0,
        boxShadow: '0 10px 75px #212',
        p: 8,
        textAlign: 'center',
      }}>
        <Logo height="100" width="270" />
      </Box>
      <Section>
        <FeedbachCard isOpen card={card} />
      </Section>
    </Main>
  );
};

export default FeedbachCardApp;
