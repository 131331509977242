import { H2 } from "../../../components/Headers";
import { Box } from "theme-ui";
import { useTranslations } from "../../../contexts/Translation";
import List from "../../../components/List/List";
import { useUserManagement } from "./UserManagementContext";
import UserItem from "./UserItem";

const UserSection = () => {
  const t = useTranslations('userManagementForm');
  const { orgUsers } = useUserManagement();
  return (
    <Box>
      <H2>{t('title')}</H2>
      { orgUsers && (
        <List items={orgUsers.map(u => <UserItem key={u.userId} {...u} />)} /> 
      )}
    </Box>
  );
};

export default UserSection;