import { useTranslations } from "../../contexts/Translation";
import { Response as ResponseType } from '../../types';
import { Response } from './Response';
import HorizontalNavigation from "../HorizontalNavigation/Index";
import Summary from "./Summary";
import Section from "../Section";
import { useApiData } from "../../contexts/ApiData";
import { useEffect, useState } from "react";
import SystemMessage from '../SystemMessage/Index';

type ResponsesProps = {
  surveyId: string;
  candidate: string;
  status?: string;
  isShared?: boolean;
};

const Responses = ({ isShared = false, surveyId, candidate, status }: ResponsesProps) => {
  const t = useTranslations('questions');
  const { get } = useApiData();
  const [responses, setResponses] = useState<ResponseType[] | null>(null);
  
  useEffect(() => {
    const callOwn = async () => {
      if (surveyId) {
        const responses = await get(`/survey/${surveyId}/responses`);
        setResponses(responses);
      }
    };
    const callShared = async () => {
      if (surveyId) {
        const responses = await get(`/shared-survey/${surveyId}/responses`);
        setResponses(responses);
      }
    }
    if (isShared) {
      callShared();
    } else {
      callOwn();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId]);

  if (!responses) {
    return <SystemMessage message="loading" />;
  }

  const labels: string[] = [t('summary')];
  const pages = [
    <Summary isShared={isShared} surveyId={surveyId} status={status} responses={responses} candidate={candidate} />,
    ...responses.map((response: ResponseType)  => (
        <Response key={response.responder} candidate={candidate} {...response} />
      ))]
  return (
    <Section>
      <HorizontalNavigation labels={labels} pages={pages} />
    </Section>
  );
};

export default Responses;
