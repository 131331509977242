import { useTranslations } from "../../contexts/Translation";
import { useState } from 'react';
import { Box, Grid, Link } from "theme-ui";
import { useApiData } from "../../contexts/ApiData";
import { Department, ValidationError } from "../../types";
import List from "../List/List";
import Input from "../Forms/Input";
import { H2 } from "../Headers";
import Button, { IconButton } from "../Button";
import { useOrganizations } from "../../contexts/UserProvider";
import { DeleteItem, Undo } from "../Icons";
import { useUserManagement } from "./UserManagement/UserManagementContext";

type ComponentProps = {
  closeFn: () => void;
};

type ItemProps = Department & {
  toggleDeleted: (id: string, isDeleted: boolean) => void;
};

const DepartmentListItem = ({name, id, toggleDeleted}: ItemProps) => {
  const [ isDeleted, setIsDeleted ] = useState(false);
  const deleteItem = () => {
      toggleDeleted(id || "", !isDeleted);
      setIsDeleted(!isDeleted);
  };

  return (
    <Box sx={{ textDecoration: isDeleted ? 'line-through': 'none' }}>{name}
      { !!id && <IconButton sx={{ border: 'none', color: 'muted'}} onClick={deleteItem}>{ isDeleted ? <Undo /> : <DeleteItem />}</IconButton> }
    </Box>
  );
};

const DepartmentForm = ({ closeFn }: ComponentProps) => {
  const t = useTranslations('userManagementForm');
  const { departments, setDepartments } = useUserManagement();

  const { currentOrganization } = useOrganizations();
  const { post, del } = useApiData();
  const [newDepartments, setNewDepartments] = useState<Department[]>([{ name: '', org: currentOrganization }]);
  const [deletedDepartments, setDeletedDepartments] = useState<Department[]>([]);
  const [errors, setErrors] = useState<ValidationError[]>([]);

  const goBack = (e: any) => {
    e.preventDefault();
    closeFn();
  };

  const handleDepartmentChange = (index: number, value: string) => {
    const deps:Department[] = [...newDepartments];

    deps[index] = {
      ...deps[index],
      name: value
    };

    if (index === deps.length - 1 && value) {
      deps.push({ name: '', org: currentOrganization });
    }
    setNewDepartments(deps);
  };

  const handleBlur = (index: number) => {
    const { name } = newDepartments[index];
    if (name.length < 1) {
      setErrors([...errors, { name: `department-${index}`, message: t('invalidDepartment') }]);
    } else {
      setErrors(errors.filter(e => e.name !== `department-${index}`));
    }
  };

  const toggleDeleted = (id: string, isDeleted: boolean) => {
    const department = departments.find(d => d.id === id);
    if (department) {
      setDeletedDepartments(isDeleted ? [...deletedDepartments, department] : deletedDepartments.filter(d => d.id !== id));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = newDepartments.filter(({ name }) => name !== '');

    if (data.length > 0) {
      const result = await post(`/organization/${currentOrganization}/departments`, data);
      if (result) {
        setDepartments([...departments, ...result]);
        setNewDepartments([{ name: '', org: currentOrganization }]);
      } else {
        setErrors([{ name: 'form', message: t('misc.serverError') }]);
      }
    }
    if (deletedDepartments.length > 0) {
      Promise.all(deletedDepartments.map(async ({ id }) => {
        await del(`/organization/${currentOrganization}/departments/${id}`);
      })).then(() => {
        setDepartments(departments.filter(d => !deletedDepartments.find(dd => dd.id === d.id)));
        closeFn();
      });
    }
  };

  const closeLink = <Link sx={{ ml: '1rem', fontSize: 12, fontWeight: 'normal', cursor: 'pointer', textDecoration: 'underline' }} onClick={goBack}>{t('goBack')}</Link>;
  return (
    <Box as="form" onSubmit={handleSubmit}>
      <H2>{t(departments.length === 0 ? 'addDepartment': 'editDepartment')} {closeLink}</H2>
      <List items={departments.map((d: Department) => <DepartmentListItem toggleDeleted={toggleDeleted} {...d} />)} />
      {newDepartments.map(({ name }, index) => (
        <Grid key={index} mb={3} sx={{
          alignItems: 'end',
          gridTemplateColumns: '5fr 1fr',
          gap: 16,
          mb: 16
        }}>
          <Box sx={{
            '> div': { mb: 0 },
            'input': { mb: 0 },
          }}>
            <Input
              error={errors.find(e => e.name === `department-${index}`)}
              name={`department-${index}`}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDepartmentChange(index, e.target.value)}
              handleBlur={() => handleBlur(index)}
              label={t('departmentNameLabel')}
              helpText={t('help.departmentName')}
              value={name}
            />
          </Box>
        </Grid>
      ))}
      <Button>{t('submit')}</Button> {closeLink}
    </Box>
  );
};

export default DepartmentForm;