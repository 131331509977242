import { PixelCrop } from 'react-image-crop'


export async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0,
) {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  // Calculate the crop size with the scale and pixel ratio applied
  let cropWidth = Math.floor(crop.width * scaleX * pixelRatio);
  let cropHeight = Math.floor(crop.height * scaleY * pixelRatio);

  // Calculate the maximum allowed dimensions
  const maxWidth = 300 * pixelRatio;
  const maxHeight = 300 * pixelRatio;

  // Calculate the scaling needed to fit the image within 300x300
  const widthScale = maxWidth / cropWidth;
  const heightScale = maxHeight / cropHeight;
  const minScale = Math.min(widthScale, heightScale, 1); // Ensure we don't scale up

  cropWidth *= minScale;
  cropHeight *= minScale;

  canvas.width = cropWidth;
  canvas.height = cropHeight;

  ctx.scale(pixelRatio * minScale, pixelRatio * minScale);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  // Convert degrees to radians for rotation
  const rotateRads = rotate * (Math.PI / 180);
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // Transformations applied in reverse order
  ctx.translate(-cropX, -cropY); // 5
  ctx.translate(centerX, centerY); // 4
  ctx.rotate(rotateRads); // 3
  ctx.scale(scale, scale); // 2
  ctx.translate(-centerX, -centerY); // 1

  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}
