import { useTranslations } from "../../contexts/Translation";
import { Box } from "theme-ui";
import { H2, H4 } from "../Headers";
import { Response as ResponseType } from '../../types';
import Quote from "../Quote";
import Invites from "./Invites";
import { Reflections } from "./Reflections";
import { CreateImprovementGoalLink } from "./CreateImprovementGoalLink";
import Comments from "../Comments/List";
import { getQuoteName } from "../../lib/getQuoteName";
import Answer from "./Answer";
import { useSurvey } from "../../contexts/Survey";
import { useProfile } from "../../contexts/UserProvider";
import Collapsable from "../Collapsable/Collapsable";
import getQuestionsForSurveyType from "../../lib/getQuestionsForSurveyType";

type SummaryProps = {
  responses: ResponseType[];
  candidate: string;
  surveyId: string;
  status?: string;
  isShared?: boolean;
};

type QuestionScore = {
  [key: string]: QuestionResult;
};

type QuestionResult = {
  dontKnows: number;
  totalScore: number;
  answers: number;
};

type ScoreResult = {
  answers: number;
  dontKnows: number;
  totalScore: number;
  questions: QuestionScore;
};

const calculateTotalScore = (responses: ResponseType[]): ScoreResult => {
  const result: ScoreResult = {
    answers: 0,
    dontKnows: 0,
    totalScore: 0,
    questions: {
      knowledge: { dontKnows: 0, answers: 0, totalScore: 0 },
      knowledgeSharing: { dontKnows: 0, answers: 0, totalScore: 0 },
      influence: { dontKnows: 0, answers: 0, totalScore: 0 },
      collaboration: { dontKnows: 0, answers: 0, totalScore: 0 },
      subjectKnowledge: { dontKnows: 0, answers: 0, totalScore: 0 },
      engaging: { dontKnows: 0, answers: 0, totalScore: 0 },
      relevance: { dontKnows: 0, answers: 0, totalScore: 0 },
      satisfaction: { dontKnows: 0, answers: 0, totalScore: 0 },
      expertise: { dontKnows: 0, answers: 0, totalScore: 0 },
      communication: { dontKnows: 0, answers: 0, totalScore: 0 },
      expectations: { dontKnows: 0, answers: 0, totalScore: 0 },
      quality: { dontKnows: 0, answers: 0, totalScore: 0 },
    },
  };

  let totalValue = 0;

  responses.forEach(response => {
    response.answers.forEach(answer => {
      if (answer.value > 0) {
        totalValue += answer.value;
        result.answers++;
        result.questions[answer.question].answers++;
        result.questions[answer.question].totalScore += answer.value;
      } else {
        result.dontKnows++;
        result.questions[answer.question].dontKnows++;
      }
    });
  });

  result.totalScore = result.answers === 0 ? 0 : totalValue / result.answers;
  return result;
};

const Summary = ({ isShared = false, responses, candidate, surveyId, status }: SummaryProps) => {
  const t = useTranslations('questions');
  const score = calculateTotalScore(responses);
  const survey = useSurvey();
  const profile = useProfile();

  const recommendations = responses.filter(r => !!r.recommendation);
  const messages = responses.filter(r => !!r.message);

  const canCreateGoal = (survey?.userId === profile?.userId && !survey?.onBehalfOf) || profile?.userId === survey?.onBehalfOf;

  const questions = getQuestionsForSurveyType(survey?.surveyType || 'long-term');
  return (
    <Box>
      <H2>{t('summary')}</H2>
      <Collapsable toggleItem={t('recommendationsHeading')} closed={false}>
        <>
          { recommendations.map(r => <Quote key={r.responder} from={getQuoteName(r.recommendationName, r.recommendationTitle, r.responder)}>{r.recommendation}</Quote>)}
        </>
      </Collapsable>
      
      { messages.length > 0 && (
        <Collapsable toggleItem={t('messagesHeading')} closed={false}>
            { messages.map((r, index) => <Box sx={{ 
              borderBottom: '1px solid #ccc',
              padding: 8,
              mb: 16,
              }} key={index}>
                <H4 key={`${index}-2`} sx={{ mt: 8, overflow: 'hidden' }}>{t('feedbackFromHeading', r.responder)}</H4>
                <Box key={index} sx={{ whiteSpace: 'pre-wrap' }}>{r.message}</Box>
              </Box>)}
          </Collapsable>
        )
      }
      
      <Collapsable toggleItem={t('answersHeading')} closed={false}>
        <>
        {questions.map(q => (
          <Answer
            key={q}
            question={q}
            candidate={candidate}
            totalScore={score.questions[q as keyof QuestionScore]?.totalScore}
            answers={score.questions[q as keyof QuestionScore]?.answers}
            dontKnows={score.questions[q as keyof QuestionScore]?.dontKnows}
          />))}
        </>
      </Collapsable>
      
      <Collapsable toggleItem={t('commentsHeading')} closed={false}>
        <Comments surveyId={surveyId} />
      </Collapsable>

      { !isShared && (status === 'upcoming' || status === 'ongoing') && (
        <Collapsable toggleItem={t('invitedHeading')} closed={false}>
          <Invites surveyId={surveyId} />
        </Collapsable>
      )}
      
      { status === 'finished' && (
        <>
          <Reflections />
          { canCreateGoal && <CreateImprovementGoalLink surveyId={surveyId} isShared={isShared} /> }
        </>
      )}
    </Box>
  );
};

export default Summary;
