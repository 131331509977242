import { Box } from "theme-ui";
import { H1, H2, H3 } from "../Headers";
import { IconButton } from "../Button";
import { useState } from "react";
import { Help } from "../Icons";
import { InfoMessage } from "../InfoMessage/Index";


const getHeadingComponent = (level: number) => {
  if (level === 1) return H1;
  if (level === 2) return H2;
  if (level === 3) return H3;
}

const HeadingWithInfo = ({ heading, level = 3, info }: { heading: string; level?: number; info: React.ReactNode | string }) => {
  const [isOpen, setIsOpsen] = useState(false);
  const HeadingComponent = getHeadingComponent(level) as React.ElementType;
  return (
    <Box>
      <HeadingComponent>{heading}
        <IconButton sx={{ border: 'none', color: isOpen ? 'infoBorder' : 'mainText'}} onClick={() => setIsOpsen(!isOpen)}><Help /></IconButton>
      </HeadingComponent>
      { isOpen && <InfoMessage type='info'>{info}</InfoMessage> }
    </Box>
  )
};

export default HeadingWithInfo;