
import { BaseThemeCompProp } from '../../types';
import { Button as TUIButton } from 'theme-ui';

const Button = ({ children, sx, disabled }: BaseThemeCompProp) => {
  return (
    <TUIButton disabled={disabled} sx={{ cursor: 'pointer', my: 16, p: 8, ...sx }} type="submit">{children}</TUIButton>
  );
};

export default Button;
