
import { PhoneAndroid } from '@emotion-icons/material';
import { Email as EmailIcon } from '@emotion-icons/material';
import { Error, LogInCircle as LoginIcon, EditAlt, LogOutCircle as LogoutIcon, UserPlus as UserAdd, UserCircle as UserIcon, InfoCircle } from '@emotion-icons/boxicons-solid';
import { Check, CalendarExclamation, LineChart, IdCard, Copy as CopyIcon, Undo as UndoIcon, ChevronDown, ChevronUp, Time, HelpCircle } from '@emotion-icons/boxicons-regular';
import { BookmarkCheckFill, ExclamationOctagonFill, PlusCircle, ArrowRight, ArrowLeft, PersonCheckFill, GraphUp, GraphDown } from '@emotion-icons/bootstrap';
import GraphicsProps from './GraphicProps';
import { Survey as SurveyIcon } from '@emotion-icons/remix-fill';
import { CalendarClock, Delete } from '@emotion-icons/fluentui-system-filled';
import { LightbulbFilament } from '@emotion-icons/fluentui-system-regular';
import { OfficeBuilding } from '@emotion-icons/heroicons-outline';

export const Phone = ({ width = '30px', height = '30px' }: GraphicsProps) => <PhoneAndroid height={height} width={width} />
export const Email = ({ width = '30px', height = '30px' }: GraphicsProps) => <EmailIcon height={height} width={width} />
export const Login = ({ width = '30px', height = '30px' }: GraphicsProps) => <LoginIcon height={height} width={width} />
export const SignUp = ({ width = '30px', height = '30px' }: GraphicsProps) => <UserAdd height={height} width={width} />
export const User = ({ width = '30px', height = '30px' }: GraphicsProps) => <UserIcon height={height} width={width} />
export const Plus = ({ width = '30px', height = '30px' }: GraphicsProps) => <PlusCircle height={height} width={width} />
export const Logout = ({ width = '30px', height = '30px' }: GraphicsProps) => <LogoutIcon height={height} width={width} />
export const Left = ({ width = '30px', height = '30px' }: GraphicsProps) => <ArrowLeft height={height} width={width} />
export const Right = ({ width = '30px', height = '30px' }: GraphicsProps) => <ArrowRight height={height} width={width} />
export const Edit = ({ width = '30px', height = '30px' }: GraphicsProps) => <EditAlt height={height} width={width} />
export const PersonCheck = ({ width = '30px', height = '30px' }: GraphicsProps) => <PersonCheckFill height={height} width={width} />
export const TimeSince = ({ width = '30px', height = '30px' }: GraphicsProps) => <CalendarClock height={height} width={width} />
export const CheckMark = ({ width = '30px', height = '30px' }: GraphicsProps) => <Check height={height} width={width} />
export const ErrorIcon = ({ width = '30px', height = '30px' }: GraphicsProps) => <Error height={height} width={width} />
export const CalendarError = ({ width = '30px', height = '30px' }: GraphicsProps) => <CalendarExclamation height={height} width={width} />
export const TrendUp = ({ width = '30px', height = '30px' }: GraphicsProps) => <GraphUp height={height} width={width} />
export const TrendDown = ({ width = '30px', height = '30px' }: GraphicsProps) => <GraphDown height={height} width={width} />
export const FailIcon = ({ width = '30px', height = '30px' }: GraphicsProps) => <ExclamationOctagonFill height={height} width={width} />
export const SuccessIcon = ({ width = '30px', height = '30px' }: GraphicsProps) => <BookmarkCheckFill height={height} width={width} />
export const DeleteItem = ({ width = '30px', height = '30px' }: GraphicsProps) => <Delete height={height} width={width} />
export const Survey = ({ width = '30px', height = '30px' }: GraphicsProps) => <SurveyIcon height={height} width={width} />
export const Improvement = ({ width = '30px', height = '30px' }: GraphicsProps) => <LineChart height={height} width={width} />
export const FeedbachCard = ({ width = '30px', height = '30px' }: GraphicsProps) => <IdCard height={height} width={width} />
export const OrgDash = ({ width = '30px', height = '30px' }: GraphicsProps) => <OfficeBuilding height={height} width={width} />
export const Copy = ({ width = '30px', height = '30px' }: GraphicsProps) => <CopyIcon height={height} width={width} />
export const Undo = ({ width = '30px', height = '30px' }: GraphicsProps) => <UndoIcon height={height} width={width} />
export const Expand = ({ width = '30px', height = '30px' }: GraphicsProps) => <ChevronDown height={height} width={width} />
export const Expanded = ({ width = '30px', height = '30px' }: GraphicsProps) => <ChevronUp height={height} width={width} />
export const LightBulb = ({ width = '30px', height = '30px' }: GraphicsProps) => <LightbulbFilament height={height} width={width} />
export const Waiting = ({ width = '30px', height = '30px' }: GraphicsProps) => <Time height={height} width={width} />
export const Info = ({ width = '30px', height = '30px' }: GraphicsProps) => <InfoCircle height={height} width={width} />
export const Help = ({ width = '30px', height = '30px' }: GraphicsProps) => <HelpCircle height={height} width={width} />
