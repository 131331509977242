import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeUIProvider } from 'theme-ui';
import { theme } from './theme';
import { TranslationProvider } from './contexts/Translation';
import { translations } from './lib/i18n';
import { ApiDataProvider } from './contexts/ApiData';
import { LoginProvider } from './contexts/LoginProvider';
import { BrowserRouter } from "react-router-dom";
import { OrganizationProvider } from './contexts/UserProvider';
import ResponseApp from './ResponseApp';
import FeedbachCardApp from './FeedbachCardApp';
import { InvitationHandler, InvitationLoggedInHandler } from './components/Invitation';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (/^\/join\/.*/.test(window.location.pathname)) {
  root.render(
    <React.StrictMode>
      <ThemeUIProvider theme={theme}>
        <TranslationProvider translations={translations}>
            <InvitationHandler>
              <Auth0Provider
                domain="feedbach.eu.auth0.com"
                clientId="CDexPbRfbOZz4k0oB4ulXvipXH6neXYY"
                authorizationParams={{
                  redirect_uri: `${process.env.REACT_APP_LOGIN_REDIRECT}/join`,
                  audience: "https://feedbach.eu.auth0.com/api/v2/",
                  scope: "openid profile email read:current_user update:current_user_metadata" // todo: Remove the claims?
                }}
              >
              <LoginProvider>
                <ApiDataProvider>
                  <InvitationLoggedInHandler />
                </ApiDataProvider>
              </LoginProvider>
            </Auth0Provider>
          </InvitationHandler>
        </TranslationProvider>
      </ThemeUIProvider>
    </React.StrictMode>
  );
} else if (/^\/c\/.*/.test(window.location.pathname)) {
  root.render(
    <React.StrictMode>
      <ThemeUIProvider theme={theme}>
        <TranslationProvider translations={translations}>
          <ApiDataProvider isPublic>
            <FeedbachCardApp />
          </ApiDataProvider>
        </TranslationProvider>
      </ThemeUIProvider>
    </React.StrictMode>
  );
} else if (/^\/respond\/.*/.test(window.location.pathname)) {
  root.render(
    <React.StrictMode>
      <ThemeUIProvider theme={theme}>
        <TranslationProvider translations={translations}>
          <ApiDataProvider isPublic>
            <ResponseApp />
          </ApiDataProvider>
        </TranslationProvider>
      </ThemeUIProvider>
    </React.StrictMode>
  );
} else {

  root.render(
    <React.StrictMode>
      <ThemeUIProvider theme={theme}>
        <TranslationProvider translations={translations}>
          <Auth0Provider
            domain="feedbach.eu.auth0.com"
            clientId="CDexPbRfbOZz4k0oB4ulXvipXH6neXYY"
            authorizationParams={{
              redirect_uri: `${process.env.REACT_APP_LOGIN_REDIRECT}`,
              audience: "https://feedbach.eu.auth0.com/api/v2/",
              scope: "openid profile email read:current_user update:current_user_metadata" // todo: Remove the claims?
            }}
          >
            <LoginProvider>
              <ApiDataProvider>
                <OrganizationProvider>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </OrganizationProvider>
              </ApiDataProvider>
            </LoginProvider>
          </Auth0Provider>
        </TranslationProvider>
      </ThemeUIProvider>
    </React.StrictMode>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
