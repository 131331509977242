import { Box, Flex, Paragraph } from "theme-ui";
import { Info } from "emotion-icons/material";
import Section from "../Section";
import { H3 } from "../Headers";

export const InfoMessageBox = ({ title, body }: { title?: string; body: string; }) => (
  <Section>
    <Flex sx={{
      alignItems: 'flex-start',
      border: '2px',
      borderColor: 'borderColor',
      background: 'tertiaryBackground',
      borderRadius: 6,
      py: 12,
      px: 20,
      'svg': { color: 'borderColor', flexShrink: 0 },
    }}>
      <Info size="1.5rem" />
      <Box sx={{ ml: 10 }}>
        { title && <H3 sx={{ mt: 0 }}>{title}</H3> }
        <Paragraph>{body}</Paragraph>
      </Box>
    </Flex>
  </Section>
);
