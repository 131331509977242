
import { ReactNode } from 'react';
import { Box } from 'theme-ui';
import Link from '../Link';

type ComponentProps = {
    text: string;
    children: ReactNode;
}
export const PopupMenu = ({ text, children }: ComponentProps) => {
  return (
    <Box sx={{ position: 'relative', mr: '1rem', display: 'inline-block', ':hover': { '.popupmenu': { display: 'block' }} }}>
      <Link sx={{ textDecoration: 'none', color: 'mainText', pb: 4 }} href={'#'}>
        {text}
      </Link>
      <Box
        className="popupmenu"
        sx={{
          display: 'none',
          position: 'absolute',
          left: '-50%',
          top: '100%', // Position the menu just below the link
          bg: 'background',
          mt: 4,
          zIndex: 200,
          fontFamily: 'interaction',
          flexDirection: 'column',
          backgroundColor: 'background',
          lineHeight: 2.5,
          border: '1px solid', borderColor: 'muted', borderRadius: '16px',
          boxShadow: '7px 10px 14px 3px rgba(0,0,0,0.26)',
          p: 24, width: '340px' }}>

        {children}
      </Box>
    </Box>
  );
};

