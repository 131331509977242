import { useImprovementGoal } from "../../contexts/ImprovementGoal";
import { InitialPhaseComponent } from "./InitialPhaseComponent";


const InitialPhase = ({ isEdit = false }) => {
  const { goal, improvementData, questions } = useImprovementGoal();

  if (!goal) {
    return null;
  }

  return (
    <InitialPhaseComponent questions={questions} goal={goal} isEdit={isEdit} improvementData={improvementData} />
  );
};

export default InitialPhase;
