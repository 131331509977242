import { useApiData } from '../../../contexts/ApiData';
import { useOrganizations } from '../../../contexts/UserProvider';
import { Department, Group, OrgUser } from '../../../types';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import SystemMessage from '../../SystemMessage/Index';
import { useTranslations } from '../../../contexts/Translation';

type UserManagementContextType = {
  groups: Group[];
  departments: Department[];
  orgUsers: OrgUser[];
  setOrgUsers: (users: OrgUser[]) => void;
  setDepartments: (departments: Department[]) => void;
  setGroups: (groups: Group[]) => void;
};

// Create the context
const UserManagementContext = createContext<UserManagementContextType>({ groups: [], departments: [], orgUsers: [], setOrgUsers: () => {}, setDepartments: () => {}, setGroups: () => {} });

// Custom hook to use the UserManagementContext
export const useUserManagement = () => {
  return useContext(UserManagementContext);
};

// The context provider component
export const UserManagementProvider = ({ children }: { children: ReactNode}) => {
  const t = useTranslations('userManagementForm');
  
  const { currentOrganization, organizations } = useOrganizations();
  const { get } = useApiData();
  
  const emptyDepartment = { id: undefined, name: (organizations && organizations[0]?.name) || t('noDepartment'), org: currentOrganization };

  const [ orgUsers, setOrgUsers ] = useState<OrgUser[]>([]);
  const [ departments, setDepartments] = useState<Department[]>([emptyDepartment]);
  const [ groups, setGroups] = useState<Group[]>([]);
  
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isLoadingDepartments, setIsLoadingDepartments ] = useState(true);
  const [ isLoadingGroups, setIsLoadingGroups ] = useState(true);

  useEffect(() => {
    const call = async () => {
      const response = await get(`/organization/${currentOrganization}/user`);
      setIsLoading(false);
      if (response) {
        setOrgUsers(response);
      }      
    };
    call();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const call = async () => {
      const response = await get(`/organization/${currentOrganization}/departments`);
      setIsLoadingDepartments(false);
      if (response) {
        setDepartments([emptyDepartment, ...response]);
      }      
    };
    call();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const call = async () => {
      const response = await get(`/organization/${currentOrganization}/groups`);
      setIsLoadingGroups(false);
      if (response) {
        setGroups(response);
      }      
    };
    call();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || isLoadingDepartments || isLoadingGroups) {
    return <SystemMessage message="loading" />
  }

  return (
    <UserManagementContext.Provider
      value={{
        orgUsers,
        departments,
        groups,
        setOrgUsers,
        setDepartments,
        setGroups,
      }}
    >
      {children}
    </UserManagementContext.Provider>
  );
};
