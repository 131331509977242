import { ReactNode } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import SystemMessage from '../components/SystemMessage/Index';

type LoginProviderProps = {
  children: ReactNode;
};

export const LoginProvider: React.FC<LoginProviderProps> = ({ children }) => {
  const { user, isLoading, error, loginWithRedirect } = useAuth0();
  if (isLoading) {
    return <SystemMessage message="loading" />;
  }

  if (error) {
    console.error(error);
    return <SystemMessage isError message="errorLogin" />;
  }

  if (!user) {
    loginWithRedirect();
    return <SystemMessage message="login" />;
  }

  return <>{children}</>;
};
