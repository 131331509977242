import { BaseThemeCompProp } from '../../types';
import { Box } from 'theme-ui';

export const H1 = ({ children, sx, ...props }: BaseThemeCompProp) => (
  <Box as="h1" sx={{
    fontSize: 40,
    fontFamily: 'heading',
    my: 28,
    ...sx
  }} {...props}>
    {children}
  </Box>
);

export const H2 = ({ children, sx, ...props }: BaseThemeCompProp) => (
  <Box as="h2" sx={{
    fontSize: 32,
    fontFamily: 'heading',
    my: 28,
    ...sx
  }} {...props}>
    {children}
  </Box>
);

export const H3 = ({ children, sx, ...props }: BaseThemeCompProp) => (
  <Box as="h3" sx={{
    fontSize: 20,
    fontFamily: 'heading',
    mt: 28,
    mb: 8,
    ...sx
  }} {...props}>
    {children}
  </Box>
);

export const H4 = ({ children, sx, ...props }: BaseThemeCompProp) => (
  <Box as="h4" sx={{
    fontSize: 16,
    my: 6,
    ...sx
  }} {...props}>
    {children}
  </Box>
);

export const H5 = ({ children, sx, ...props }: BaseThemeCompProp) => (
  <Box as="h5" sx={{
    fontSize: 25,
    my: 4,
    ...sx
  }} {...props}>
    {children}
  </Box>
);

export const H6 = ({ children, sx, ...props }: BaseThemeCompProp) => (
  <Box as="h6" sx={{
    fontSize: 20,
    my: 2,
    ...sx
  }} {...props}>
    {children}
  </Box>
);
