import { Help } from "../../../components/Icons";
import { H3 } from "../../../components/Headers";
import { useTranslations } from "../../../contexts/Translation";

const SurveyInfo = ({ isEdit, surveyStatus }: { isEdit?: boolean; surveyStatus?: string }) => {
  const t = useTranslations('surveyInfo');
  if (surveyStatus === 'finished') {
    return (
      <>
        <H3 sx={{ mt: 0 }}><Help /> {t('heading')}</H3>
        <p>{t('finished1')}</p>
        <p>{t('finished2')}</p>
      </>);
  }
  if (surveyStatus === 'ongoing') {
    return (<>
      <H3 sx={{ mt: 0 }}><Help /> {t('heading')}</H3>
      <p>{t('ongoing1')}</p>
      <p>{t('ongoing1')}</p>
    </>);
  }

  return (
    <>
      <H3 sx={{ mt: 0 }}><Help /> {t('heading')}</H3>
      <p>{t('aboutTo')}</p>
      <p>{t('invitations')}</p>
      <p>{t('emailSend')}</p>
      <p>{t('access')}</p>
      <p>{t('slowInvites')}</p>
      <p>{t('goodLuck')}</p>
    </>
  );
}

export default SurveyInfo;