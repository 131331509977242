import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useApiData } from './ApiData';
import { Survey } from '../types';

const SurveyContext = createContext<Survey | null>(null);

type SurveyProviderProps = {
  children: ReactNode;
  surveyId: string;
  isShared?: boolean;
};

export const useSurvey = () => {
  const survey = useContext(SurveyContext);
  if (!survey) {
    return null;
  }
  return survey;
};

export const SurveyProvider: React.FC<SurveyProviderProps> = ({ children, surveyId, isShared = false }) => {
  const { get } = useApiData();
  const [survey, setSurvey] = useState<Survey | null>(null);

  useEffect(() => {
    const callOwn = async () => {
      const surveyResult = await get(`/survey/${surveyId}`);
      if (surveyResult) {
        surveyResult.isShared = false;
        setSurvey(surveyResult);
      }
    }
    const callShared = async () => {
      const surveyResult = await get(`/shared-survey/${surveyId}`);
      if (surveyResult) {
        surveyResult.isShared = true;
        setSurvey(surveyResult);
      }
    }
    if (isShared) {
      callShared();
    } else {
      callOwn();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId]);

  return (
    <SurveyContext.Provider value={survey}>
      {children}
    </SurveyContext.Provider>
  );
};
