import { SubscriptionData, SubscriptionStatus, SubscriptionType } from "../../types";
import { useOrganizations, useRole } from "../../contexts/UserProvider";
import { useTranslations } from "../../contexts/Translation";
import { ExpiredSubscriptionMessage } from "./ExpiredSubscriptionMessage";

type Pattern = {
  subExpired: Function;
  trialExpired: Function;
  noSubscription: Function;
  active: Function;
  noSubscriptionNotOwner: Function;
};

const getInfoMessageBox = ({ type, status }: SubscriptionData, role: string | undefined, match: Pattern) => {
  if (status === SubscriptionStatus.Active) {
    return match.active();
  }

  if (role !== 'owner') {
    match.noSubscriptionNotOwner();
  }

  if (status === SubscriptionStatus.Ended) {
    return type === SubscriptionType.Trial
      ? match.trialExpired()
      : match.subExpired();
  }

  return match.noSubscription();
};

export const CheckSubscription = () => {
  const { subscription, currentOrganization } = useOrganizations();
  const role = useRole();
  const t = useTranslations('dashboard');

  return currentOrganization && getInfoMessageBox(subscription, role, {
    subExpired: () =>
      <ExpiredSubscriptionMessage button={t('activeSubscription')} title={t('expiredSubTitle')} body={t('expiredSubBody')} />,
    active: () =>
      null,
    trialExpired: () =>
      <ExpiredSubscriptionMessage button={t('activeSubscription')} title={t('trialExpiredSubTitle')} body={t('trialExpiredSubBody')} />,
    noSubscription: () =>
      <ExpiredSubscriptionMessage button={t('activeSubscription')} title={t('noSubTitle')} body={t('noSubBody')} />,
    noSubscriptionNotOwner: () => 
      <ExpiredSubscriptionMessage title={t('expiredSubTitle')} body={t('expiredSubBodyNotOwner')} />
  })
};
