import { Help } from "../../../components/Icons";
import { H3 } from "../../../components/Headers";
import { useTranslations } from "../../../contexts/Translation";


const GoalHelp = ({ isEdit }: { isEdit: boolean; }) => {
  const t = useTranslations('goalHelp');
  if (isEdit) {
    return (
      <>
        <H3 sx={{ mt: 0 }}><Help /> {t('heading')}</H3>
        <p>{t('phases')}</p>
        <p>{t('initialPhase')}</p>
        <p>{t('executionPhase')}</p>
        <p>{t('conclusionPhase')}</p>
        <p>{t('acheivement')}</p>
        <p>{t('goodLuck')}</p>
      </>
    )
  }
  return (
    <>
      <H3 sx={{ mt: 0 }}><Help /> {t('heading')}</H3>
      <p>{t('aboutTo')}</p>
      <p>{t('phases')}</p>
      <p>{t('initialPhase')}</p>
      <p>{t('executionPhase')}</p>
      <p>{t('conclusionPhase')}</p>
      <p>{t('acheivement')}</p>
      <p>{t('goodLuck')}</p>
    </>
  );
}

export default GoalHelp;