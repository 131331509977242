import { useTranslations } from "../../contexts/Translation";
import { Box, Grid } from "theme-ui";
import { Answer, Response as ResponseType } from '../../types';
import Quote from "../Quote";
import { H2, H3, H4 } from "../Headers";
import { getQuoteName } from "../../lib/getQuoteName";
import EmScore from "./EmScore";
import { ResponderNotes } from "./ResponderNotes";

type ResponseProps = ResponseType & {
  candidate: string;
}

export const Response = ({ status, candidate, responder, recommendation, recommendationName, recommendationTitle, message, answers, notes }: ResponseProps) => {
  const t = useTranslations('questions');

  const Answer = ({ question, value, }: Answer) => {
    return (<>
      <H4 sx={{ margin: 0 }}>{t(question, candidate)}</H4>
      <Box><EmScore size={3} sx={{ margin: '0 auto' }} score={value} /></Box>
      </>
    )
  };

  return (
    <Box>
      <H2>{t('responseHeading', responder)}</H2>
      { status !== 'responded' && <Box as="p">{t('hasNotResponded', responder)}</Box>}
      { recommendation &&
        <><H3>{t('recommendationHeading')}</H3>
          <Quote from={getQuoteName(recommendationName, recommendationTitle, '')}>{recommendation}</Quote>
        </>
      }

      { message &&
        <>
          <H3>{t('messageHeading')}</H3>
          <Box as="p" sx={{ whiteSpace: 'pre-wrap' }}>{message}</Box>
        </>
      }
      { answers.length > 0 && (<>
        <H3>{t('answersHeading')}</H3>
        <Grid sx={{
          fontSize: 36,
          alignItems: 'center', 
          gridTemplateColumns: '5fr 1fr',
          gridTemplateRows: 'repeat(4 1fr)',
          rowGap: 32,
          my: 32, borderTop: '1px solid #999',
          borderBottom: '1px solid #999',
          borderColor: 'muted', py: 16, }}
        >
          { answers.map(a => <Answer key={a.question} {...a} />)}
        </Grid>
        <ResponderNotes notes={notes} responder={responder} />
      </>)}

      
    </Box>
  );
};
