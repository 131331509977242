import { Button as TUIButton } from "theme-ui";
import { BaseThemeCompProp } from '../../types';

type ButtonProps = BaseThemeCompProp & {
  onClick?: () => void;
  title?: string;
  type?: 'button' | 'submit' | 'reset';
};

export const LinkButton = ({ children, sx, onClick, type = 'button', ...props }: ButtonProps) => (
  <TUIButton onClick={(e) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  }} sx={{
    width: 'fit-content',
    backgroundColor: 'transparent',
    color: 'mainText',
    textDecoration: 'underline',
    cursor: 'pointer',
    p: 2,
    ...sx,
  }} {...props}>
    {children}
  </TUIButton>
);

const Button = ({ children, sx, onClick, type = 'submit', ...props }: ButtonProps) => (
  <TUIButton onClick={(e) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  }}
   sx={{
    width: 'fit-content',
    color: 'mainText',
    background: 'primary',
    cursor: 'pointer',
    fontFamily: 'interaction',
    py: 12,
    px: 20,
    borderRadius: 8,
    type: type,
    ...sx,
  }} {...props}>
    {children}
  </TUIButton>
);

export const IconButton = ({ children, sx, onClick, title, type = 'button', ...props }: ButtonProps) => (
  <TUIButton onClick={(e) => {
    e.preventDefault();
    onClick?.();
  }} sx={{
    width: 'fit-content',
    backgroundColor: 'transparent',
    color: 'mainText',
    cursor: 'pointer',
    p: 12,
    type: type,
    ...sx,
  }} title={title} {...props}>
    {children}
  </TUIButton>
);

export default Button;
