import { Box } from "theme-ui";

type ComponentProps = {
  children?: React.ReactNode;
  label?: string;
  value?: string;
}
export const InfoItem = ({ children, label, value }: ComponentProps) => (
  <Box sx={{
    display: 'inline-block',
    width: '40%',
    borderBottom: '1px',
    mr: 32,
    my: 16,
  }}>
    { !!label ? <>{label}: <strong>{value}</strong></> : <>{children}</>}
  </Box>
);
