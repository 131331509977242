import { useParams, useSearchParams } from "react-router-dom";
import { Routes as Router, Route } from "react-router-dom";
import FeedbachCard from "./components/FeedbachCard/Index";
import Settings from "./components/Settings/Index";
import CardForm from "./components/Forms/CardForm";
import CardRouteHandler from "./components/FeedbachCard/RouteHandler";
import Surveys from "./components/Survey/Surveys";
import Recommendations from "./components/Recommendations/Recommendations";
import Dashboard from "./components/Dashboard/Index";
import ImprovementGoals from "./components/ImprovementGoal/List";
import OrgDashboard from "./components/OrgDashboard/Index";
import OrgGoalForm from "./components/Forms/OrgGoalForm";
import { SubscriptionActivated } from "./components/Subscription/SubscriptionActivated";
import SystemMessage from './components/SystemMessage/Index';
import SurveyDetails from "./components/Survey/Details";
import { SurveyProvider } from "./contexts/Survey";
import ImprovementGoalForm from "./components/Forms/ImprovementGoalForm";
import { ImprovementGoalProvider } from "./contexts/ImprovementGoal";
import ImprovementGoalDetails from "./components/ImprovementGoal/Details";
import { OrgGoalProvider } from "./contexts/OrganizationGoal";
import OrgGoalDetails from "./components/OrgDashboard/OrgGoalDetails";

import { ActivateSubscription } from "./components/Subscription/ActivateSubscription";
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from "react";
import { useApiData } from "./contexts/ApiData";
import RegisterOrganization from "./components/Dashboard/RegisterOrganization";
import SurveyWizard from "./components/Forms/SurveyWizard/SurveyWizard";

const SurveyWrapper = ({ isShared = false }) => {
  const { id } = useParams();
  if (!id) {
    return <SystemMessage message="loading" />;
  }
  return (
    <SurveyProvider isShared={isShared} surveyId={id}>
      <SurveyDetails isShared={isShared} />
    </SurveyProvider>
  )
};

const OrgGoalWrapper = () => {
  const { id } = useParams();
  if (!id) {
    return <SystemMessage message="loading" />;
  }
  return (
    <OrgGoalProvider goalId={id}>
      <OrgGoalDetails />
    </OrgGoalProvider>
  )
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK || '');
const StripeWrapper = () => {
  const [clientSecret, setClientSecret] = useState("");
  const { post } = useApiData();
  useEffect(() => {
    const createPaymentIntent = async () => {
      const result = await post('/payment/payment-intent');
      setClientSecret(result.clientSecret);
    };

    createPaymentIntent();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stripeOptions: StripeElementsOptions = {
    clientSecret,
    appearance: { theme: 'stripe'},
  };

  if (!clientSecret) {
    return null;
  }

  return (
      <Elements options={stripeOptions} stripe={stripePromise}>
        <ActivateSubscription />
      </Elements>
    );
};


const ImprovementGoalWrapper = ({ isShared = false }) => {
  const { id } = useParams();
  if (!id) {
    return <SystemMessage message="loading" />;
  }
  return (
    <ImprovementGoalProvider isShared={isShared} goalId={id}>
      <ImprovementGoalDetails isShared={isShared} />
    </ImprovementGoalProvider>
  )
};

const ImprovementGoalFormWrapper = ({ isShared = false }) => {
  const [searchParams] = useSearchParams();
  const fromSurvey = searchParams.get('fromSurvey');
  if (!fromSurvey) {
    return <SystemMessage message="loading" />;
  }
  return (
    <ImprovementGoalProvider isShared={isShared} surveyId={fromSurvey}>
      <ImprovementGoalForm surveyId={fromSurvey} />
    </ImprovementGoalProvider>
  )
};


const Routes = () => (
  <Router>
    <Route path="/" element={<Dashboard />} />
    <Route path="/register-organization" element={<RegisterOrganization />} />
    <Route path="/organization" element={<OrgDashboard />} />
    <Route path="/organization/goal" element={<OrgGoalForm />} />
    <Route path="/organization/goal/:id" element={<OrgGoalWrapper />} />
    <Route path="/cards" element={<CardRouteHandler />} />
    <Route path="/card" element={<CardForm />} />
    <Route path="/survey" element={<SurveyWizard />} />
    <Route path="/recommendations" element={<Recommendations />} />
    <Route path="/surveys" element={<Surveys />} />
    <Route path="/improvements" element={<ImprovementGoals />} />
    <Route path="/improvement" element={<ImprovementGoalFormWrapper />} />
    <Route path="/improvement/:id" element={<ImprovementGoalWrapper />} />
    <Route path="/survey/:id" element={<SurveyWrapper />} />
    <Route path="/card/:id" element={<FeedbachCard />} />
    <Route path="/shared/:id" element={<SurveyWrapper isShared />} />
    <Route path="/shared" element={<Surveys isShared />} />
    <Route path="/shared-cards" element={<CardRouteHandler isShared />} />
    <Route path="/shared-improvements" element={<ImprovementGoals isShared />} />
    <Route path="/shared-improvement/:id" element={<ImprovementGoalWrapper isShared />} />
    <Route path="/settings" element={<Settings />} />
    <Route path="/activate-subscription" element={<StripeWrapper />} />
    <Route path="/subscription-activatecd" element={<SubscriptionActivated />} />
  </Router>
);
export default Routes;