import { Box } from "theme-ui";
import { useTranslations } from "../../../contexts/Translation";
import { InfoMessage } from "../../../components/InfoMessage/Index";
import { useUserManagement } from "./UserManagementContext";
import Button from "../../../components/Button";
import List from "../../../components/List/List";
import HeadingWithInfo from "../../../components/HeadingWithInfo/HeadingWithInfo";

type ComponentProps = {
  setShowDepartments: (show: boolean) => void;
};

const DepartmentsSection = ({ setShowDepartments }: ComponentProps ) => {
  const t = useTranslations('userManagementForm');
  const { departments } = useUserManagement();
  return (
    <Box>
      <HeadingWithInfo heading={t('departmentsTitle')} info={t('departmentsInfo')} />
      {  departments.length === 0 && <InfoMessage>{t('noDepartments')}</InfoMessage> }
      { departments.length > 0 && <List items={departments.map(d => <Box>{d.name}</Box>)} /> }
      <Button onClick={() => setShowDepartments(true)}>{t(departments.length === 0 ? 'addDepartment': 'editDepartment')}</Button>  
    </Box>
  );
};

export default DepartmentsSection;