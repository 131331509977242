// src/components/SurveyTypeFilter.tsx

import { useTranslations } from '../../contexts/Translation';
import { Survey, SurveyFilterType } from '../../types';
import React, { useMemo } from 'react';
import { Button, Box } from 'theme-ui';

interface SurveyTypeFilterProps {
  surveys: Survey[];
  selectedFilter: SurveyFilterType;
  onFilterChange: (filterType: SurveyFilterType) => void;
  isDisabled: boolean;
}

const SurveyTypeFilter: React.FC<SurveyTypeFilterProps> = ({
  surveys,
  selectedFilter,
  onFilterChange,
  isDisabled = false,
}) => {
  const t = useTranslations('cardForm');

  /**
   * Extract unique survey types from the surveys prop.
   * This ensures that buttons are dynamically generated based on available types.
   */
  const surveyTypes = useMemo(() => {
    const types = surveys.map((survey) => survey.surveyType);
    return Array.from(new Set(types));
  }, [surveys]);

  /**
   * Include an 'All' option to allow resetting the filter.
   */
  const allSurveyTypes: SurveyFilterType[] = useMemo(() => ['all', ...surveyTypes], [surveyTypes]);

  /**
   * Handle the filter logic when a button is clicked.
   * Calls the onFilterChange callback with the selected filter type.
   */
  const handleFilter = (type: SurveyFilterType) => {
    onFilterChange(type);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        mb: 4,
        alignItems: 'center',
      }}
    >
      { t('surveyTypeFilterLabel') }
      {allSurveyTypes.map((type) => (
        <Button
          key={type}
          onClick={(e) => { e.preventDefault(); handleFilter(type) }}
          variant={selectedFilter === type ? 'primary' : 'outline'}
          aria-pressed={selectedFilter === type}
          disabled={isDisabled}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textWrap: 'nowrap',
            opacity: isDisabled ? 0.5 : 1,
            transition: 'background-color 0.3s, color 0.3s',
            '&:hover': {
              backgroundColor: 'primary',
              color: 'white',
            },
          }}
        >
          {type.charAt(0).toUpperCase() + type.slice(1)}
        </Button>
      ))}
    </Box>
  );
};

export default React.memo(SurveyTypeFilter);
