import { Box, Flex } from "theme-ui";
import { InfoItem } from "./InfoItem";
import EmScore from "./EmScore";
import { H4 } from "../Headers";
import { useTranslations } from "../../contexts/Translation";

type ComponentProps = {
  question: string;
  dontKnows?: number;
  score?: number;
  totalScore?: number | undefined;
  answers: number | undefined;
  candidate: string;
}

const Answer = ({ question, dontKnows, totalScore = 0, score, answers = 0, candidate }: ComponentProps) => {
  const t = useTranslations('questions');
  return (
  <Box sx={{ my: 32, borderTop: '1px solid #999', ':last-child': { pb: 32, borderBottom: '1px solid #999' } }}>
    <H4>{t(question, candidate)}</H4>
    <Flex sx={{ flexDirection: 'row', alignItems: 'center' }}>
      <Box sx={{ width: '50%', p: 3, fontSize: 28 }}>
        <EmScore
          size={4}
          sx={{ mx: 'auto' }}
          score={score ? score : (totalScore / (answers === 0 ? 1 : answers))}
          after={t(`${question}Label`)}
          afterSx={{ bottom: '-1.2em' }}
        />
      </Box>
      <Box sx={{ width: '50%', p: 3, }}>
        <InfoItem>{t('resultAnswers', answers)}</InfoItem>
        { (!!dontKnows || dontKnows === 0) && <InfoItem>{t('resultDontKnows', dontKnows)}</InfoItem> }
      </Box>
    </Flex>
  </Box>
  )
};

export default Answer;
