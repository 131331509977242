import { Box } from "theme-ui";
import { H3 } from "../Headers";
import { useTranslations } from "../../contexts/Translation";
import { useSurvey } from "../../contexts/Survey";
import { useApiData } from "../../contexts/ApiData";
import Input from "../Forms/Input";
import { useState } from "react";
import Button, { IconButton } from "../Button";
import { ValidationError } from "../../types";
import ErrorText from "../Forms/ErrorText";
import { CheckMark, Edit } from "../Icons";
import { useProfile } from "../../contexts/UserProvider";

export const Reflections = () => {
  const t = useTranslations('surveys');
  const { put } = useApiData();
  const survey = useSurvey();
  const profile = useProfile();
  const [ reflections, setReflections ] = useState(survey?.reflections || '');
  const [ isSaved, setIsSaved ] = useState(false);
  const [ isEdit, setIsEdit ] = useState(false);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    setIsSaved(false);
    setReflections(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const result = await put(`/survey/${survey?.id}/reflections`, { reflections });
    if (!result) {
      setErrors([{ name: 'form', message: t('serverError') }]);
    } else {
      setIsSaved(true);
    }
  };
  
  const canEdit = (profile?.userId === survey?.userId && !survey?.onBehalfOf) || profile?.userId === survey?.onBehalfOf;

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <ErrorText sx={{ mt: 32, fontSize: 24, }} error={errors.find(e => e.name === 'form')} />
      <H3>{t('reflectionsHeading')}
          { canEdit && <IconButton sx={{ border: 'none', color: isEdit ? 'primary' : 'mainText'}} onClick={() => setIsEdit(!isEdit)}><Edit /></IconButton> }
      </H3>
      { canEdit && isEdit ? (
        <>
          <Input
            type="textarea"
            name="reflections"
            value={reflections}
            handleChange={handleChange}
            />
          <Button>{t('submitReflections')}</Button> { isSaved && <CheckMark />}
        </>
      ): <Box as="p" sx={{ whiteSpace: 'pre-wrap' }}>{reflections || t('noReflectionsText')}</Box> }
    </Box>
  );
};
