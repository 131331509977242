// ActivateSubscription.tsx

import Section from '../Section';
import { useTranslations } from '../../contexts/Translation';
import { H1 } from '../Headers';
import { Box, Paragraph as P } from 'theme-ui';
import Button from '../Button';
import { useApiData } from '../../contexts/ApiData';
import { InfoMessageBox } from '../InfoMessage/InfoMessageBox';

export const ActivateSubscription = () => {
  const t = useTranslations('subscription');
  const { post } = useApiData();
  const isCancelled = window.location.search.includes('cancelled=true');

  const getCheckoutSessionUrl = async () => {
    const response = await post('/payment/create-checkout-session');
    window.location.href = response.gotoUrl;
  };

  return (
    <Section>
      <H1>{t('activateSub')}</H1>
      { isCancelled && <InfoMessageBox body={t('checkoutCancelled')} /> }
      <P>{t('subscriptionOrderText')}</P>
      <Box sx={{ my: 16 }}>
        <Button onClick={getCheckoutSessionUrl}>
          {t('goToCheckout')}
        </Button>
      </Box>
      <P>{t('subscriptionOrderInfo')}</P>
    </Section>
  );
};
