import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useApiData } from './ApiData';
import { OrgGoal, Stats } from '../types';
import { scoreResult2ScoresObject } from '../lib/mapping';
import getQuestionsForSurveyType from '../lib/getQuestionsForSurveyType';

type OrgGoalContextType = {
  goal: OrgGoal | null;
  recentStats: Stats | null;
  questions: string[];
}

const OrgGoalContext = createContext<OrgGoalContextType>({ goal: null, recentStats: null, questions: [] });

type OrgGoalProviderProps = {
  children: ReactNode;
  goalId?: string;
};

export const useOrgGoal = () => {
  return useContext(OrgGoalContext);
};

export const OrgGoalProvider: React.FC<OrgGoalProviderProps> = ({ children, goalId }) => {
  const { get } = useApiData();
  const [goal, setGoal] = useState<OrgGoal | null>(null);
  const [questions, setQuestions] = useState<string[]>([]);
  const [recentStats, setRecentStats] = useState<Stats | null>(null);

  const mapToScoreObject = (scoreString: string) => {
    const scores = scoreString.split(',');

    const mappedArr = scores.map((s, i) => {
      try {
        const value = parseFloat(s);
        return { question: questions[i], score: value };
      } catch (ex) {
        return { question: questions[i], score: 0 };
      }
    });
    return scoreResult2ScoresObject(mappedArr);
  }

  useEffect(() => {
    const getRecentScore = async () => {
      const response = await get(`/organization/stats?fromDate=${goal?.registered?.substring(0, 10)}&toDate=${goal?.endDate}&surveyType=${goal?.surveyType}`);
      setRecentStats(response);
    }
    if (goal) {
      getRecentScore();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal])

  useEffect(() => {
    if (!goalId) {
      return;
    }
    const getGoal = async () => {
      const result = await get(`/organization-goal/${goalId}`);
      if (result) {
        setGoal({ ...result, scoreData: mapToScoreObject(result.originalScores) });
        setQuestions(getQuestionsForSurveyType(result.surveyType));
      }
    }
    getGoal();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalId]);

  return (
    <OrgGoalContext.Provider value={{ goal, recentStats, questions }}>
      {children}
    </OrgGoalContext.Provider>
  );
};
