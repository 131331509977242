import { Box } from 'theme-ui';
import { WithSx } from '../../types';
import { ReactNode } from 'react';

type LinkProps = WithSx & {
  items: ReactNode[];
};

const List = ({ items, sx }: LinkProps) => (
  <Box as="ul" sx={{ listStyle: 'none', listStylePosition: 'inside', pl: 0, mx: 0, my: 16, '> li': { py: 8, }, ...sx }}>
    { items.map((item, idx) => (<li key={idx}>{item}</li>))}
  </Box>
)

export default List;
