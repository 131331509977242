import { useTranslations } from "../../../contexts/Translation";
import { CheckMark, ErrorIcon, Expand, Expanded } from "../../../components/Icons";
import { useApiData } from "../../../contexts/ApiData";
import { useOrganizations } from "../../../contexts/UserProvider";
import { Group, OrgUser } from "../../../types";
import { ReactElement, useState } from "react";
import { Box, Flex } from "theme-ui";
import { useUserManagement } from "./UserManagementContext";
import GroupSelector from "./GroupSelector";
import { Select } from "../Input";
import Button from "../../../components/Button";

const UserItem = ({ candidate, email, userId, role, department }: OrgUser) => {
  const t = useTranslations('userManagementForm');
  
  const { put } = useApiData();
  const { currentOrganization } = useOrganizations();
  const { departments, groups } = useUserManagement();
  const [selectedRole, setSelectedRole] = useState<string>(role);
  const [selectedDepartment, setSelectedDepartment] = useState<string | undefined>(department);
  const [addedGroups, setAddedGroups] = useState<Group[]>([]);
  const [deletedGroups, setDeletedGroups] = useState<Group[]>([]);

  const [isExpanded, setIsExpanded] = useState(false);
  const [icon, setIcon] = useState<ReactElement | null>(null);

  const handleChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newRole = e.target.value;
    setSelectedRole(newRole);
  };

  const handleDepartmentChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newDepartment = e.target.value;
    setSelectedDepartment(newDepartment);
  };

  const updateGroups = (selected: Group[], added: Group[], deleted: Group[]) => {
    setAddedGroups(added);;
    setDeletedGroups(deleted)
  };

  const updateUser = async () => {
    const result = await put(`/organization/${currentOrganization}/user/${userId}`, { role: selectedRole, departmentId: selectedDepartment, addedGroups, deletedGroups });
    if (result) {
      setAddedGroups([]);
      setDeletedGroups([]);
      setIcon(<CheckMark />);
    } else {
      setIcon(<ErrorIcon />);
    }
  };

  return (
    <>
      <Box onClick={() => setIsExpanded(!isExpanded)} sx={{ display: 'flex', cursor: 'pointer', py: 8, borderBottom: isExpanded ? 'none': '1px solid #ddd', justifyContent: 'space-between' }}>
        {candidate} ({ email })<Box sx={{ display: 'flex', justifyContent: 'right' }}>{ isExpanded ? <Expanded /> : <Expand /> }</Box>
      </Box>
      <Flex sx={{ display: !isExpanded ? 'none': 'block', flexDirection: 'row', py: 8, borderBottom: '1px solid #ddd' }}>
        <Box sx={{ alignItems: 'right', width: '33%' }}>
          <Select
            options={[
              { value: 'owner', text: t('roleOwner') },
              { value: 'invited', text: t('roleInvited') },
              { value: 'member', text: t('roleMember') },
              { value: 'supporter', text: t('roleSupporter') },
            ]}
            label={t('role')}
            readOnly={role === 'owner'}
            helpText={t('help.roleForUser')}
            name={`${candidate}_name`}
            handleChange={handleChange}
            value={`${selectedRole}`}
          />
        </Box>
        <Box sx={{ alignItems: 'right', width: '33%' }}>
          <Select
            name={`${candidate}_department`}
            label={t('department')}
            helpText={t('help.departmentForUser')}
            handleChange={handleDepartmentChange}
            value={selectedDepartment || ''}
            options={departments.map((d) => ({ value: d.id || '', text: d.name }))}
          />
        </Box>
        <GroupSelector helpText={t('help.groupsForUser')} groups={groups} userId={userId} onChange={updateGroups} />
        <Button type="button" onClick={() => updateUser()}>{t('save')}</Button> { icon }
      </Flex>
    </>
  )
};

export default UserItem;