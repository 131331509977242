import React, { useRef } from "react";
import { Box } from "theme-ui";

type ImageUploadProps = {
  handleChange: (dataUrl: string) => void;
  existingImg?: string;
}

export default function ImageUpload({ handleChange, existingImg }: ImageUploadProps) {
  const imgRef = useRef<HTMLImageElement>(null);

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
          handleChange(reader.result?.toString() || '');
        }
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  return (
    <Box sx={{ my: 16 }}>
      <input type="file" accept="image/*" onChange={onSelectFile} />
      <Box sx={{ mt: 8 }}>
      {!!existingImg && (
        <img
          ref={imgRef}
          width={100}
          alt="Crop me"
          src={existingImg}
        />
      )}
      </Box>
    </Box>
  );
}
