import { Avatar, Box, Text, Flex, Grid, Image } from 'theme-ui';
import Logo, { Note } from '../Icons/Logo';
import Section from '../Section';
import EmScore from '../Survey/EmScore';
import { QRCodeSVG } from 'qrcode.react';
import { PersonCheck, TimeSince } from '../Icons';
import { useDateFormatter, useTranslations } from '../../contexts/Translation';
import { H1, H2, H3 } from '../Headers';
import { useEffect, useMemo, useRef, useState } from 'react';
import CardStats from './CardStats';
import { LinkButton } from '../Button';
// @ts-ignore
import { useScreenshot } from 'use-react-screenshot';
import CopyValue from '../CopyValue';
import getQuestionsForSurveyType from '../../lib/getQuestionsForSurveyType';

const Card = ({ card, isPreview, setImage }: any) => {
  const d = useDateFormatter();
  const t = useTranslations('card');
  const [showInfo, setShowInfo] = useState(false);
  const cardUrl = card.cardId ? `https://www.feedbach.me/c/${card.cardId}` : '';
  const ref = useRef(null);
  const [image, takeScreenshot] = useScreenshot();

  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };

  useEffect(() => {
    if (isPreview) {
      takeScreenshot(ref.current)
    }
  }, [isPreview, takeScreenshot]);

  useEffect(() => {
    if (setImage) {
      setImage(image);
    }
  }, [setImage, image]);

  const questions = useMemo(() => {
    return getQuestionsForSurveyType(card.surveyType);
  }, [card.surveyType]);

  return (
    <>
    <Section sx={{
      border: '1px',
      borderColor: '#d8d8d8',
      backgroundColor: '#fafafa',
      p: 0,
      px: 0,
      m: 0,
      mt: 32,
      position: 'relative',
      overflow: 'hidden',
      fontSize: ['16px', '32px']
    }}>
      <div ref={ref}>
        <Flex sx={{ justifyContent: 'space-between', zIndex: 100, position: 'relative' }}>
          <Flex sx={{
            pt: '1em',
            pl: '1em',
            alignItems: 'center'
          }}>
            <Avatar src={card.image} sx={{
              width: '5.25em',
              height: '5.25em',
            }} />
            <Flex sx={{ flexDirection: 'column', ml: '1em' }}>
              <H1 sx={{ fontSize: '1.25em', m: 0 }}>
                {card.candidate}
              </H1>
              <H2 sx={{
                fontSize: '0.75em',
                color: 'mutedText',
                mb: 0,
                mt: '0.25em',
                fontWeight: 400
              }}>
                {card.title}
              </H2>
              { card.cardTitle && (
                <H3 sx={{
                  fontSize: '0.5em',
                  color: 'mutedText',
                  mb: 0,
                  mt: '0.25em',
                  fontWeight: 400
                }}>
                {card.cardTitle}
                </H3>
              ) }
            </Flex>
          </Flex>
          {card.logo && (
            <Image
              src={card.logo}
              sx={{
                width: '2em',
                height: '0.75em',
                mt: '0.625em',
                mr: '0.625em',
                maxWidth: 150,
                maxHeight: 50
              }} alt="Company logo" />
          )}
        </Flex>
        <Flex sx={{ mt: '0.75em', mb: '0.5em', justifyContent: 'space-between', zIndex: 100, position: 'relative' }}>
          <Box sx={{ ml: '1em', display: 'flex', justifyContent: 'center' }}>
            <EmScore inside={t('totalScoreLabel')} score={card.totalScore} size={6} sx={{ width: '6em' }} />
          </Box>
          <Flex sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            <Grid sx={{
              pr: 0,
              justifyItems: 'center',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gridTemplateRows: 'repeat(1, 1fr)'
            }}>
              {questions.map((q, i) => (<EmScore key={`${q}_${i}`} after={t(`${q}Label`)} size={2.55} score={card[q]} />))}
            </Grid>
            <Flex sx={{ alignItems: 'center', mt: '1.5em', 'svg': { mr: '0.5em' } }}>
              <Flex sx={{ alignItems: 'center', mr: '1em' }} title={t('noOfResponders')}>
                <Text sx={{ color: 'mutedText' }}><PersonCheck width="1.25em" height="1.25em" /></Text>
                <Text sx={{ fontSize: '0.875em' }}>{card.responders}</Text>
              </Flex>
              <Flex sx={{ alignItems: 'center' }} title={t('latestResponse')}>
                <Text sx={{ color: 'mutedText' }}><TimeSince width="1.25em" height="1.25em" /></Text>
                <Text sx={{ fontSize: '0.875em' }}>{d(card.latestResponse)}</Text>
              </Flex>
            </Flex>
          </Flex>
          <Box sx={{ mr: '0.5em', alignSelf: 'flex-end' }}>
            <Box sx={{ display: 'block', margin: '0 auto', textAlign: 'center' }}>
              <QRCodeSVG height="2em" width="2em" value={cardUrl || '<thisrandomstringmakesthislookcool!>'} />
            </Box>
            <Box sx={{ justifyContent: 'center', display: 'flex', mt: 16 }}><Logo width={'2m'} height={'1.2em'} fillColor='#441144' /></Box>
          </Box>
        </Flex>
        <Box sx={{ position: 'absolute', top: '1.5rem', right: '4.6rem', zIndex: 1, }}>
          <Note fillColor='#f1f1f1' height={'500px'} width={'250px'} />
        </Box>
      </div>
    </Section>
    { !isPreview && (
      <Box sx={{ fontSize: 12, textAlign: 'center', mt: 4 }} as="p">
        <CopyValue variant="text" value={cardUrl} />
         - <LinkButton onClick={toggleInfo}>{t('moreInfo')}</LinkButton>
      </Box>)}
    {showInfo && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
          }}
          onClick={toggleInfo}
        >
          <Box
            sx={{
              backgroundColor: 'white',
              borderRadius: '4px',
              padding: '20px',
              maxWidth: '90%',
              maxHeight: '90%',
              overflowY: 'auto',
            }}
          >
            <CardStats isOpen card={card} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Card;
