
import { InputProps } from '../../types';
import { Input as TUIInput, Label, Textarea, Box, Checkbox, Select as TUISelect, Radio } from 'theme-ui';
import ErrorText from './ErrorText';
import { IconButton } from '../Button';
import { Help } from '../Icons';
import { ReactNode, useState } from 'react';

export const HelpText = ({ text, isOpen }: { text: ReactNode | string | undefined; isOpen: boolean; }) => {
  if (text && isOpen) {
    return <Box sx={{ border: '1px solid', borderColor: 'infoBorder', px: 4, py: 8, mb: 4, fontSize: 12, bg: 'info' }}><Help /> {text}</Box>;
  }
  return null;
}

export const HelpIcon = ({ text, isOpen, setIsOpen }: { text?: ReactNode | string | undefined; isOpen: boolean; setIsOpen: (isOpen: boolean) => void; }) => {
  if (!text) {
    return null;
  }
  return <IconButton sx={{ color: isOpen ? 'infoBorder': 'mainText', position: 'relative', top: '-5px', p: 0, mb: 0 }} onClick={() => setIsOpen(!isOpen)}><Help /></IconButton>;
};

const Input = ({ infoText, helpText, name, value = '', type = 'text', label, readOnly = false, handleChange, handleBlur, error, sx, csx }: InputProps) => {
  let InputComponent = null;
  const [helpOpen, setHelpOpen] = useState(false);

  if (type === 'textarea') {
    InputComponent = (
      <Textarea
        sx={{ mt: 8, height: '120px', p: 12, borderRadius: 8, backgroundColor: error ? 'errorMuted': null, ...sx }}
        readOnly={readOnly}
        name={name}
        id={name}
        onChange={handleChange}
        value={`${value}`} />
    );
  } else if (type === 'checkbox') {
    InputComponent = (
      <Checkbox
        name={name}
        id={name}
        sx={{  }}
        mb={3}
        value="1"
        checked={value === 1 || value === '1'}
        onChange={handleChange}
      />
    )
  } else {
    InputComponent = (
      <TUIInput
        name={name}
        type={type}
        id={name}
        readOnly={readOnly}
        sx={{ mt: 8, mb: 16, p: 12, borderRadius: 8, backgroundColor: error ? 'errorMuted': null, ...sx }}
        value={`${value}`}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    );
  }
  const border = error ? '3px solid #b20303' : 'none';

  return (
    <Box sx={{ position: 'relative', my: 16, ':first-of-type': { mt: 0, py: 4 }, borderBottom: border, borderTop: border, ...csx }}>
      { type === 'checkbox' ? <Label>{ InputComponent } {label}</Label>
      : (<>
        <Label sx={{ fontSize: 18 }} htmlFor={name}>
          {label}
          <HelpIcon text={helpText} isOpen={helpOpen} setIsOpen={setHelpOpen} />
        </Label>
        <HelpText text={helpText} isOpen={helpOpen} />

        { InputComponent }
        
        </>)}
      <ErrorText error={error} />
      { infoText && <Box sx={{ mt: 16 }}>{infoText}</Box>}
    </Box>
  );
};

export default Input;

export const Select =  ({ helpText, options = [], readOnly, infoText, name, value = '', label, handleChange, handleBlur, error, sx, csx }: InputProps) => {
  const border = error ? '3px solid #b20303' : 'none';
  const [helpOpen, setHelpOpen] = useState(false);

  return (
    <Box sx={{ my: 16, ':first-of-type': { mt: 0 }, borderBottom: border, borderTop: border, ...csx }}>
      <Label sx={{ fontSize: 18 }} htmlFor={name}>
        {label}
        <HelpIcon text={helpText} isOpen={helpOpen} setIsOpen={setHelpOpen} />
      </Label>
      <HelpText text={helpText} isOpen={helpOpen} />

      <TUISelect
        name={name}
        id={name}
        sx={{ mt: 8, p: 12, borderRadius: 8, ...sx }}
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={readOnly}
        value={`${value}`}>
          { options.map((o, i) => <option key={`${i}-${value}`} value={o.value}>{o.text}</option>) }
      </TUISelect>
      <ErrorText error={error} />
      { infoText && <Box>{infoText}</Box>}
    </Box>
  );
};

export const RadioGroup = ({ helpText, options = [], readOnly, infoText, name, value = '', label, handleChange, handleBlur, error, sx }: InputProps) => {
  const border = error ? '3px solid #b20303' : 'none';
  const [helpOpen, setHelpOpen] = useState(false);

  return (
    <Box sx={{ my: 16, ':first-of-type': { mt: 0 }, borderTop: border, borderBottom: border }}>
      { label && <Box as="p" sx={{ pb: 8 }}>{label} <HelpIcon text={helpText} isOpen={helpOpen} setIsOpen={setHelpOpen} /></Box> }
      <HelpText text={helpText} isOpen={helpOpen} />

      { options.map((o, i) => (
        <Label key={`radio-${i}`}>
          <Radio
            name={name}
            onChange={handleChange}
            readOnly={readOnly}
            value={`${o.value}`}
            checked={value === o.value}
          />
          {o.text}
        </Label>
      ))}
      
      <ErrorText error={error} />
      { infoText && <Box>{infoText}</Box>}
    </Box>
  );
};
