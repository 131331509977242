import React, { useEffect, useState } from 'react';
import { Box, Flex, Progress } from 'theme-ui';
import { useLocale, useTranslations } from '../../../contexts/Translation';
import { H3 } from '../../Headers';
import Section from '../../Section';
import { useApiData } from '../../../contexts/ApiData';
import { v4 as uuid } from 'uuid';
import Button from '../../Button';
import Input, { Select } from '../Input';
import { EmailLang, Profile, Survey, ValidationError } from '../../../types';
import { useNavigate } from 'react-router-dom';
import { useSurvey } from '../../../contexts/Survey';
import { useOrganizations, useProfile, useRole } from '../../../contexts/UserProvider';
import LanguageSelector from '../../LanguageSelector/Index';
import GroupAccess from '../GroupAccess';
import { EmailPreview } from './EmailPreview';
import Invites from '../../Survey/Invites';
import { Tip } from '../../Tip/Index';
import HeadingWithInfo from '../../../components/HeadingWithInfo/HeadingWithInfo';
import SurveyInfo from './SurveyInfo';
import getQuestionsForSurveyType from '../../../lib/getQuestionsForSurveyType';

type SurveyFormData = {
  id: string;
  endDate: string;
  invitationText: string;
  surveyName: string;
  defaultLang: string;
  accessibleBy: string;
  onBehalfOf: string | null;
  groupsWithAccess?: string[];
  surveyType: 'long-term' | 'workshop' | 'delivery';
};

type SurveyFormProps = {
  isEdit?: boolean
}

const SurveyWizard: React.FC<SurveyFormProps> = ({ isEdit = false }) => {
  const t = useTranslations('surveyForm');
  const lang = useLocale();
  const role = useRole();
  const profile = useProfile();
  const locale = useLocale();
  const { post, put, get } = useApiData();
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [members, setMembers] = useState<Profile[]>([]);
  const navigate = useNavigate();
  const survey: Survey | null = useSurvey();
  const { currentOrganization, currentRole } = useOrganizations();
  const [invites, setInvites] = useState<EmailLang[]>([]);


  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState<SurveyFormData>({
    id: survey?.id || uuid(),
    endDate: survey?.endDate || (new Date(Date.now() + 7*24*3600000)).toISOString().substring(0,10),
    invitationText: survey?.invitationText || '',
    surveyName: survey?.surveyName || '',
    defaultLang: survey?.defaultLang || locale || '',
    accessibleBy: survey?.accessibleBy || 'me',
    onBehalfOf: survey?.onBehalfOf || profile?.userId || null,
    groupsWithAccess: survey?.groupsWithAccess || [],
    surveyType: survey?.surveyType || 'long-term',
  });

  useEffect(() => {
    const getUsers = async () => {
      const fetchedMembers = await get('/users');
      if (fetchedMembers) {
        setMembers(fetchedMembers);
      }
    }
    if (role === 'supporter' || role === 'owner') {
      getUsers();
    }
  }, [role, get]);

  const validateStep = (stepIndex: number): ValidationError[] => {
    const errors: ValidationError[] = [];
    switch (stepIndex) {
      case 0:
        if (!formData.surveyName) errors.push({ name: 'surveyName', message: t('fieldRequired') });
        if (!formData.invitationText) errors.push({ name: 'invitationText', message: t('fieldRequired') });
        break;
      case 1:
        // Page 2 validation (currently empty)
        break;
      case 2:
        // Page 3 validation (currently empty)
        break;
      case 3:
        if (!formData.endDate) errors.push({ name: 'endDate', message: t('fieldRequired') });
        const inOneWeek = (new Date(Date.now() + 7*24*3600000)).toISOString().substring(0, 10);
        if (!isEdit && formData.endDate < inOneWeek) errors.push({ name: 'endDate', message: t('minimumDuration') });
        if (!formData.defaultLang) errors.push({ name: 'defaultLang', message: t('fieldRequired') });
        if (!formData.accessibleBy) errors.push({ name: 'accessibleBy', message: t('fieldRequired') });
        if (formData.accessibleBy === 'selectedGroups' && (!formData.groupsWithAccess || formData.groupsWithAccess.length === 0)) {
          errors.push({ name: 'groupsWithAccess', message: t('selectAtLeastOneGroup') });
        }
        break;
    }
    return errors;
  };

  const validateWholeModel = (): ValidationError[] => {
    let allErrors: ValidationError[] = [];
    for (let i = 0; i < steps.length; i++) {
      allErrors = [...allErrors, ...validateStep(i)];
    }
    return allErrors;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
      accessibleBy: name === 'onBehalfOf' && value !== profile?.userId ? 'supporters' : value,
      groupsWithAccess: name === 'accessibleBy' && value !== 'selectedGroups' ? [] : prevData.groupsWithAccess,
    }));
    setErrors(errors.filter(error => error.name !== name));
  };

  const setGroupsWithAccess = (groups: string[]) => {
    setFormData(prevData => ({ ...prevData, groupsWithAccess: groups }));
    setErrors(errors.filter(error => error.name !== 'groupsWithAccess'));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const allErrors = validateWholeModel();
    if (allErrors.length > 0) {
      setErrors(allErrors);
      return;
    }

    const result = isEdit 
      ? await put(`/survey/${formData.id}`, formData)
      : await post(`/survey/${formData.id}`, formData);
    
    if (!result) {
      setErrors([{ name: 'form', message: t('serverError') }]);
    } else {
      if (isEdit) {
        window.location.reload();
      } else {
        await post(`/survey/${result.id}/responders`, invites);
        navigate(`/survey/${result.id}`);
      }
    }
  };

  const getAccessibleByOptions = () => {
    const isOnbehalfOf = formData.onBehalfOf !== null && formData.onBehalfOf !== profile?.userId;
    if (['supporter', 'owner'].includes(currentRole) && isOnbehalfOf) {
      return [
        { value: 'supporters', text: t('accessibleBySupporters') },
        { value: 'selectedGroups', text: t('accessibleBySelectedGroups') },
      ];
    }
    return [
      { value: 'me', text: t('accessibleByMe') },
      { value: 'supporters', text: t('accessibleBySupporters') },
      { value: 'selectedGroups', text: t('accessibleBySelectedGroups') },
      { value: 'org', text: t('accessibleByOrganization') },
    ];
  };

  const steps = [
    {
      content: (
        <>
          <Input
            name="surveyName"
            handleChange={handleChange}
            label={t('surveyName')}
            value={formData.surveyName}
            error={errors.find(e => e.name === 'surveyName')}
          />

          <Select
            options={[{ value: 'long-term', text: t('surveyTypeLongTerm') }, { value: 'workshop', text: t('surveyTypeWorkshop') }, { value: 'delivery', text: t('surveyTypeDelivery') }]}
            handleChange={handleChange}
            name="surveyType"
            label={t('surveyTypeLabel')}
            value={formData.surveyType}
            helpText={t('help.surveyType')}
            error={errors.find(e => e.name === 'surveyType')}
          />
          <Tip>
            <Box>
            <Box as="p" sx={{ fontSize: 14, m: 4 }}>{t('surveyTypeNoChange')}</Box>
            <Box as="p" sx={{ fontSize: 14, m: 4 }}>{t('selectedSurveyTypeQuestions')}</Box>
            <ol>
              {getQuestionsForSurveyType(formData.surveyType).map(q => (
                <li key={q}>{t(`questions.${q}`, profile?.candidate)}</li>
              ))}
            </ol>
            </Box>
          </Tip>

          <Input
            type="textarea"
            name="invitationText"
            handleChange={handleChange}
            label={t('invitationText')}
            value={formData.invitationText}
            helpText={t('help.invitationText')}
            error={errors.find(e => e.name === 'invitationText')}
          />
        </>
      ),
    },
    {
      title: t('emailPreviewTitle'),
      infoText: t('emailPreviewInfo'),
      content: <EmailPreview invitationText={formData.invitationText} lang={lang} name={profile?.candidate || ""} />,
    },
    {
      title: t('invitesHeading'),
      infoText: t('invitesInfo'),
      content: (
        <>
          <Invites onInvitesUpdated={setInvites} displayInvitationText={false} initialEmails={invites} displayTip={false} />
          <Tip>{t('skipForLater')}</Tip>
        </>
      ),
    },
    {
      title: t('surveyAccessStep'),
      content: (
        <>
          <Input
            type="date"
            name="endDate"
            handleChange={handleChange}
            label={t('endDate')}
            helpText={t('help.endDate')}
            value={formData.endDate}
            error={errors.find(e => e.name === 'endDate')}
          />
          <LanguageSelector
            handleChange={handleChange}
            name="defaultLang"
            label={t('defaultLang')}
            helpText={t('help.defaultLang')}
            value={formData.defaultLang}
            error={errors.find(e => e.name === 'defaultLang')}
          />
          {(members && members.length > 0 && (role === 'supporter' || role === 'owner')) && (
            <Select
              options={members.map(m => ({ value: `${m.userId}`, text: `${m.candidate}` }))}
              handleChange={handleChange}
              name="onBehalfOf"
              helpText={t('help.onBehalfOf')}
              label={t('onBehalfOf')}
              value={formData.onBehalfOf || ''}
            />
          )}
          <Select
            options={getAccessibleByOptions()}
            readOnly={!currentOrganization}
            handleChange={handleChange}
            name="accessibleBy"
            label={t('accessibleBy')}
            helpText={t('help.accessibleBy')}
            value={formData.accessibleBy}
            error={errors.find(e => e.name === 'accessibleBy')}
          />
          {formData.accessibleBy === 'selectedGroups' && (
            <GroupAccess
              initiallySelected={formData.groupsWithAccess || []}
              setGroupsWithAccess={setGroupsWithAccess}
            />
          )}
        </>
      ),
    },
  ];

  const nextStep = () => {
    const stepErrors = validateStep(step);
    if (stepErrors.length === 0) {
      setStep(prevStep => Math.min(prevStep + 1, steps.length - 1));
    } else {
      setErrors(stepErrors);
    }
  };

  const prevStep = () => setStep(prevStep => Math.max(prevStep - 1, 0));
  return (
    <Section>
      <HeadingWithInfo
        level={1}
        heading={isEdit ? t('editHeading') : t('heading')}
        info={<SurveyInfo />}
      />
      <Box as="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
        { steps[step].title && (
          steps[step].infoText ? <HeadingWithInfo level={3} heading={steps[step].title || ""} info={steps[step].infoText} /> : <H3>{steps[step].title}</H3>
        )}
        {steps[step].content}
        
        <Box sx={{ my: 16 }}>
          <Progress max={steps.length} value={step + 1} />
        </Box>
        <Flex sx={{ justifyContent: 'space-between', mt: 3 }}>
          {step > 0 ? <Button onClick={prevStep}>{t('previous')}</Button> : <div />}
          {step < steps.length - 1 ? (
            <Button onClick={nextStep}>{t('next')}</Button>
          ) : (
            <Button type="submit">{t(isEdit ? 'submitEdit' : 'submit')}</Button>
          )}
        </Flex>
      </Box>
    </Section>
  );
};

export default SurveyWizard;