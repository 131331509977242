
import Main from './components/Main';
import HeadingSection from "./components/HeadingSection";
import { useTranslations } from "./contexts/Translation";
import { CheckSubscription } from "./components/Subscription/CheckSubscription";
import SystemMessage from './components/SystemMessage/Index';
import Routes from "./Routes";
import { useProfile } from './contexts/UserProvider';
import ProfileForm from './components/Forms/ProfileForm';
import Section from './components/Section';
import { useAuth0 } from '@auth0/auth0-react';

const App = () => { 
  const t = useTranslations('dashboard');
  const { user } = useAuth0();
  const profile = useProfile();
  let component = <Routes />;
  if (user?.email_verified === false) {
    component = <SystemMessage message="verifyEmail" />;
  } else if (!profile) {
    component = <Section><ProfileForm /></Section>;
  }

  return (
    <Main>
      <HeadingSection title={t('title')} />
      <CheckSubscription />
      {component}
    </Main>
  );
};

export default App;
