import { useDateFormatter } from "../../contexts/Translation";
import { WithChildren } from "../../types";
import { Box, Text } from "theme-ui";

type QuoteProps = WithChildren & {
  from?: string;
  date?: string;
}

const Quote = ({ children, from, date }: QuoteProps) => {
  const d = useDateFormatter();
  return (
    <Box sx={{
      fontSize: 24,
      fontStyle: 'italic',
      mt: 24,
      whiteSpace: 'pre-wrap',
      position: 'relative',
    }}>
      <Box sx={{ fontSize: '64px', width: '40px', color: 'muted', position: 'absolute' }}>"</Box>
      <Box sx={{ pt: '30px', pl: '40px', pb: '10px' }}>
        {children}
      </Box>
      {from ? <Box sx={{ fontStyle: 'normal', pl: 64 }}> ― {from} <Text sx={{ fontSize: 14 }}>{ !!date ? `(${d(date)})` : null}</Text></Box> : null}
    </Box>
  );
};

export default Quote;
