import { Box } from "theme-ui";
import { useTranslations } from "../../contexts/Translation";
import { Plus } from "../Icons";
import { useNavigate } from "react-router-dom";

type ComponentProps = {
  from: string;
}

export const CreateImprovementGoalLink = ({ from }: ComponentProps) => {
  const t = useTranslations('improvementGoals');
  const navigate = useNavigate();

  const navFunc = () => {
    navigate(`/organization/goal?fromDate=${from}`);
    window.scrollTo({ 
      top: 0,  
      behavior: 'smooth',
    });
  };

  return (
    <Box sx={{ mt: 32 }}>
      <Box
        sx={{
          borderWidth: '3px',
          borderRadius: '10px',
          borderStyle: 'dashed',
          borderColor: 'muted',
          color: 'mutedText',
          py: 32,
          px: 32,
          m: 0,
          mb: 16,
          cursor: 'pointer',
          textAlign: 'center',
          fontSize: 32,
          fontFamily: 'interaction',
        }}
        onClick={navFunc}>
        <Plus width='64px' height='64px' /> {t('create')}
      </Box>
    </Box>
  );
};
