import { WithChildren } from "../../types";
import { Box } from "theme-ui";

type ComponentProps = WithChildren & {
  type?: 'info' | 'warning';
}

export const InfoMessage = ({ children, type = 'warning' }: ComponentProps) => {
  const commonSx = {
    m: 16,
    p: 16,
  };
  const sx = type === 'warning' ? ({
    border: '1px solid #441144',
    borderColor: 'mainText',
    backgroundColor: 'primary',
  })
  : ({
    border: '1px solid #d8b60d',
    borderColor: 'mainText',
    backgroundColor: 'info',
  })
  return (
    <Box as="p" sx={{ ...commonSx, ...sx }}>
      {children}
    </Box>
  );
};
