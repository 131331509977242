import { useTranslations } from "../../contexts/Translation";
import { H2, H3 } from "../Headers";
import Section from "../Section";
import { useState } from "react";
import Button from "../Button";
import CropAndUpload from "./ImageUpload/CropAndUpload";
import { useApiData } from "../../contexts/ApiData";
import { Profile, SettingsFormProps, ValidationError } from "../../types";
import { Box } from "theme-ui";
import ErrorText from './ErrorText';
import Input from "./Input";
import { useAuth0 } from "@auth0/auth0-react";

type ProfileFormProps = SettingsFormProps & {
  profile?: Profile;
};

const ProfileForm = ({ isEdit = false, profile }: ProfileFormProps)  => {
  const t = useTranslations('createProfile');
  const { user } = useAuth0();
  const [ image, setImage ] = useState<string>(profile?.image || '');
  const [ candidate, setCandidate ] = useState<string>(profile?.candidate || '');
  const [ title, setTitle ] = useState<string>(profile?.title || '');
  const [ consentToTerms, setConsentToTerms ] = useState<number>(profile?.consentToTerms || 0);
  const { post, put } = useApiData();
  const [errors, setErrors] = useState<ValidationError[]>([]);

  const saveProfile = async (e: React.FormEvent) => {
    e.preventDefault();
    const apiFn = isEdit ? put : post;
    if (!user || !user.email || user.email.length < 5) {
      setErrors([...errors, { name: 'form', message: t('emailMissing')}])
    } else if (candidate.length < 2) {
      setErrors([...errors, { name: 'candidate', message: t('nameMissing')}])
    } else if (consentToTerms !== 1) {
      setErrors([...errors, { name: 'consentToTerms', message: t('consentToTermsRequired')}])
    } else {
      const result = await apiFn(`/profile`, { image, candidate, title, email: user.email, consentToTerms, });
      if (!result) {
        setErrors([{ name: 'form', message: t('serverError') }]);
        window.scrollTo({ 
          top: 0,  
          behavior: 'smooth',
        });
      } else {
        window.location.reload();
      }
    }
  };

  const updateImage = (dataUrl: string) => {
    setImage(dataUrl);
  };

  return (
      <Section>
        <H2>{t(isEdit ? 'editHeading' : 'heading')} </H2>
        <Box as="form" onSubmit={saveProfile}>
          <ErrorText sx={{ mt: 32, fontSize: 24, }} error={errors.find(e => e.name === 'form')} />
          <Input
              name="candidate"
              error={errors.find(e => e.name === 'candidate')}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => { setErrors([]); setCandidate(e.target.value);}}
              label={t('candidate')}
              helpText={t('help.candidate')}
              value={candidate}
            />
          <Input
              name="title"
              error={errors.find(e => e.name === 'title')}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => { setErrors([]); setTitle(e.target.value);}}
              label={t('title')}
              helpText={t('help.title')}
              value={title}
            />
          <H3>{t('profileImageLabel')}</H3>
          <CropAndUpload handleChange={updateImage} existingSrc={image} />
          
          <Input
            type="checkbox"
            error={errors.find(e => e.name === 'consentToTerms')}
            name="consentToTerms"
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => { setErrors([]); setConsentToTerms(e.target.checked ? 1: 0);}}
            label={t('consentToTerms')}
            value={consentToTerms}
            infoText={<><a target="_blank" rel="noreferrer" href="https://feedbach.me/agreement">{t('termsLink')}</a></>} />

          <Button sx={{ mt: 16  }}>{t(isEdit ? 'editHeading': 'saveBtn')}</Button>
        </Box>
      </Section>
  );
};

export default ProfileForm;
