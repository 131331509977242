import Answer from "../Survey/Answer";
import { Stats, SurveyType } from "../../types";
import getQuestionsForSurveyType from "../../lib/getQuestionsForSurveyType";
import { useTranslations } from "../../contexts/Translation";
import Collapsable from "../Collapsable/Collapsable";

type ComponentProps = {
  surveyType: SurveyType;
  stats: Stats;
};

const AnswersForSurveyType = ({ surveyType, stats }: ComponentProps) => {
  const t = useTranslations('orgDash');
  const questions = getQuestionsForSurveyType(surveyType);
  return (
    <Collapsable toggleItem={t(`surveyTypeHeading_${surveyType}`)} closed={false}>
      {questions.map(question => (
        <Answer
          key={question}
          question={question}
          candidate={t('candidatePlaceholder')}
          score={stats.questions.find(q => q.question === question)?.score}
          answers={stats.questions.find(q => q.question === question)?.count}
        />))}
    </Collapsable>
  );
};

export default AnswersForSurveyType;