import { ImprovementGoal } from "../../types";
import { useApiData } from "../../contexts/ApiData";
import { useDateFormatter, useTranslations } from "../../contexts/Translation";
import { H2, H3 } from "../Headers";
import Section from "../Section";
import { Box, Flex } from "theme-ui";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { InfoItem } from "../Survey/InfoItem";
import ConclusionFlag from "./ConclusionFlag";
import SystemMessage from '../SystemMessage/Index';
import { InfoMessage } from "../InfoMessage/Index";

type SurveysProps = {
  limitFinished?: number;
  isShared?: boolean;
  isOrgGoals?: boolean;
};

const ImprovementGoals = ({ isShared = false, isOrgGoals = false, limitFinished = -1}: SurveysProps) => {
  const t = useTranslations('improvementGoals');
  const d = useDateFormatter();
  const { get } = useApiData();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [goals, setGoals] = useState<ImprovementGoal[]>([]);
  
  useEffect(() => {
    const callOwn = async () => {
      const goalList = await get('/improvement-goals');
      setGoals(goalList);
      setIsLoading(false);
    }
    const callShared = async () => {
      const goalList = await get(`/shared-improvement-goals`);
      setGoals(goalList);
      setIsLoading(false);
    }
    const callOrgGoals = async () => {
      const goalList = await get(`/organization-goal`);
      setGoals(goalList);
      setIsLoading(false);
    };
    if (isShared) {
      callShared();
    } else if(isOrgGoals) {
      callOrgGoals();
    } else {
      callOwn();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNavUrl = (goal: ImprovementGoal) => {
    if (isOrgGoals) {
      return `/organization/goal/${goal.id}`;
    }
    if (isShared) {
      return `/shared-improvement/${goal.id}`;
    }
    return `/improvement/${goal.id}`;
  }

  if (isLoading) {
    return <SystemMessage message="loading" />
  }

  return (
    <Section>
      <H2>{t(isShared ? 'sharedGoalsTitle': 'goalsTitle')}</H2>
      { goals.length === 0 && <InfoMessage>{t(isShared ? 'noSharedGoals' : 'noGoals')}</InfoMessage>}
      { goals.map(goal => {
        return (
          <Box key={goal.id} sx={{
            py: 8,
            px: 32,
            m: 0,
            mb: 16,
            cursor: 'pointer',
            // ...sx,
          }} onClick={() => navigate(getNavUrl(goal))}>
            { goal.name ? <H3 sx={{ my: 8 }}>{goal.name} <ConclusionFlag isSuccess={goal.isSuccess} /></H3> : null }
            <Flex sx={{ alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1 }}>
                <InfoItem>{t('registeredLabel', d(goal.registered || ''))}</InfoItem>
                <InfoItem>{t('endDateLabel', d(goal.endDate))}</InfoItem>
              </Box>
            </Flex>
            { isShared && <Box as="p" sx={{ fontSize: 24, textAlign: 'center' }}>{t('sharedBy', goal.candidate)}</Box> }
          </Box>
        )
      })}
    </Section>
  );
};

export default ImprovementGoals;
