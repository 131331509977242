import { Survey, WithSx } from "../../types";
import { useDateFormatter, useTranslations } from "../../contexts/Translation";
import { H3 } from "../Headers";
import { Box, Flex } from "theme-ui";
import { useNavigate } from "react-router-dom";
import { InfoItem } from "./InfoItem";
import EmScore from "./EmScore";

type SurveyShortViewProps = WithSx & Survey & {
  title?: string;
  isShared?: boolean;
  candidate?: string;
  sharedBy?: string;
};

const SurveyShortView = ({ isShared = false, candidate = '', sharedBy, userId, onBehalfOf, title, id, startDate, changed, endDate, status, respondents = 0, invited = 0, score, sx }: SurveyShortViewProps) => {
  const t = useTranslations('surveys');
  const d = useDateFormatter();
  const navigate = useNavigate();

  return (
    <Box sx={{
      py: 8,
      px: 32,
      m: 0,
      mb: 16,
      cursor: 'pointer',
      ...sx,
    }} onClick={() => navigate(isShared ? `/shared/${id}`: `/survey/${id}`)}>
      { title ? <H3 sx={{ my: 8 }}>{title} - {candidate}</H3> : null }
      <Flex sx={{ alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <InfoItem>{t('start', d(startDate))}</InfoItem>
          <InfoItem>{t('end', d(endDate))}</InfoItem>
          <InfoItem>{t('respondents', respondents, invited)}</InfoItem>
          <InfoItem>{t(`status_${status}`)}</InfoItem>
        </Box>
        <EmScore size={5} status={status} score={score} />
      </Flex>
      <Box as="p" sx={{ fontSize: 18, color: 'mutedText', textAlign: 'center' }}>{ (userId !== onBehalfOf) ? <>{t('creator_onBehalfOf')} <strong>{sharedBy}</strong> - {t('changed', d(changed, true))}</> : <>{t('changed', d(changed, true))}</> }</Box>
    </Box>
  );
};

export default SurveyShortView;
