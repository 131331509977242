import { useOrganizations } from "../../contexts/UserProvider";
import { useDateFormatter, useTranslations } from "../../contexts/Translation";
import { Box } from "theme-ui";
import { H2 } from "../Headers";
import { InfoItem } from "../Survey/InfoItem";
import { useApiData } from "../../contexts/ApiData";
import Button from "../Button";
import { Tip } from "../Tip/Index";

export const SubscriptionDetails = () => {
  const { subscription } = useOrganizations();
  const { post } = useApiData();
  const t = useTranslations('subscription');
  const d = useDateFormatter();

  const openStripePortal = async () => {
    const result = await post(`/payment/create-portal-session?subscriptionId=${subscription.id}`);
    window.location.href = result.url;
  };

  return (
    <Box>
      <H2>{t('detailsHeading')}</H2>
      <Tip>{t('stripeInfo')}</Tip>
      <InfoItem label={t('subscriptionStatus')} value={t(`status_${subscription.status}`)} />
      <InfoItem label={t('startDateLabel')} value={d(subscription.startDate)} />
      <InfoItem label={t('endDateLabel')} value={d(subscription.endDate)} />
      <Box sx={{ mt: 16 }}>
        <Button onClick={() => openStripePortal()}>{t('openPortal')}</Button>
      </Box>
    </Box>
  );

};
