import { useState } from "react";
import { Box } from "theme-ui";
import { Expand, Expanded } from "../Icons";
import { H3 } from "../Headers";
import { WithSx } from "../../types";

type ComponentProps = WithSx & {
  children: React.ReactNode;
  toggleItem: React.ReactNode | string;
  closed: boolean;
};
const Collapsable = ({ children, toggleItem, closed, sx }: ComponentProps) => {
  const [isExpanded, setIsExpanded] = useState(!closed);
  return (
    <Box sx={{ mt: 32, ...sx }}>
      <Box onClick={() => setIsExpanded(!isExpanded)}
        sx={{ display: 'flex', cursor: 'pointer', py: 8, borderBottom: isExpanded ? 'none': '1px solid #ddd', justifyContent: 'space-between' }}>
        <H3 sx={{ margin: 0, padding: 0, }}>{toggleItem}</H3>
        <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>{ isExpanded ? <Expanded /> : <Expand /> }</Box>
      </Box>
      {isExpanded && children}
    </Box>
  );
};

export default Collapsable;