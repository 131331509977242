import { useAuth0 } from '@auth0/auth0-react';
import { useApiData } from '../../contexts/ApiData';
import { useTranslations } from '../../contexts/Translation';
import { useEffect, useState } from 'react';
import { Box } from 'theme-ui';
import { v4 as uuid } from 'uuid';
import { Organization, SettingsFormProps, ValidationError, ValidationResult } from '../../types';
import Input from './Input';
import ImageUpload from './ImageUpload/Upload';
import ErrorText from './ErrorText';
import { useOrganizations } from '../../contexts/UserProvider';
import Button from '../Button';
import { H2, H3 } from '../Headers';

type OrganizationFormData = {
  id: string;
  name: string;
  orgNo: string;
  country: string;
  domain: string;
  logo?: string;
  consentToTerms: number;
  consentToAgreement: number;
};

const OrganizationForm = ({ isEdit = false }: SettingsFormProps)  => {
  const t = useTranslations('orgForm');
  const { user } = useAuth0();
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [receipt, setReceipt] = useState(false);
  const { currentOrganization } = useOrganizations();
  const { post, put, get } = useApiData();
  const [formData, setFormData] = useState<OrganizationFormData>({
    id: uuid(),
    name: '',
    orgNo: '',
    country: '',
    domain: user?.email?.split('@')[1] ?? '',
    logo: '',
    consentToTerms: 0,
    consentToAgreement: 0,
  });

  useEffect(() => {
    const call = async () => {
      const response = await get('/organization');
      if (response) {
        const currentOrg = (response.find((o: Organization) => o.id === currentOrganization));
        setFormData({ ...formData, ...currentOrg });
      }      
    };

    if (isEdit) {
      call();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization]);

  const validateData = ({ name, orgNo, domain, consentToTerms, consentToAgreement }: OrganizationFormData): ValidationResult => {
    const errors: ValidationError[] = [];
    if (!name) { errors.push({ name: 'name', message: t('fieldRequired')}) }
    if (!consentToTerms) { errors.push({ name: 'consentToTerms', message: t('consentToTermsRequired')}) }
    if (!consentToAgreement) { errors.push({ name: 'consentToAgreement', message: t('consentToAgreementRequired')}) }

    return { errors: errors.length > 0 ? errors: undefined, success: errors.length === 0 };
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReceipt(false);
    setErrors(errors.filter(err => err.name !== e.target.name));
    setFormData({
      ...formData,
      [e.target.name]: e.target.type === 'checkbox' ? (e.target.checked ? 1:0) : e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationResult = validateData(formData);
    const apiFn = isEdit ? put : post;

    if (validationResult.success) {
      const result = await apiFn(`/organization/${formData.id}`, formData);
      if (!result) {
        setErrors([{ name: 'form', message: t('serverError') }]);
      } else {
        if (!isEdit) {
          window.location.reload();
        } else {
          setReceipt(true);
          window.scrollTo({ 
            top: 0,  
            behavior: 'smooth',
          }); 
        }
      }
    } else {
      setErrors(validationResult.errors || []);
      window.scrollTo({ 
        top: 0,  
        behavior: 'smooth',
      }); 
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
      <H2>{t(isEdit ? 'updateTitle': 'title')}</H2>
      { receipt && <Box as="p" sx={{ padding: 8, border: '3px solid #1c5b01'}}>{t('receiptText')}</Box>}
      { errors.filter(e => e.name !== 'form').length > 0 && <ErrorText sx={{ mt: 32, fontSize: 24, }} text={t('formErrors')} /> }
      <ErrorText sx={{ mt: 32, fontSize: 24, }} error={errors.find(e => e.name === 'form')} />
      <Input
        error={errors.find(e => e.name === 'name')}
        name="name"
        handleChange={handleChange}
        label={t('orgName')}
        value={formData.name} />

      <H3>{t('logoLabel')}</H3>
      <ImageUpload existingImg={formData.logo} handleChange={ (dataUrl: string) => setFormData({ ...formData, logo: dataUrl }) } />
      
      <Input
        type="checkbox"
        error={errors.find(e => e.name === 'consentToAgreement')}
        name="consentToAgreement"
        handleChange={handleChange}
        label={t('consentToAgreement')}
        value={formData.consentToAgreement}
        infoText={<><a target="_blank" href="/agreement">{t('agreementLink')}</a></>} />

      <Input
        type="checkbox"
        error={errors.find(e => e.name === 'consentToTerms')}
        name="consentToTerms"
        handleChange={handleChange}
        label={t('consentToTerms')}
        value={formData.consentToTerms}
        infoText={<><a target="_blank" href="/terms">{t('termsLink')}</a></>} />

      <Button>{t('submit')}</Button>
    </Box>
  );
};

export default OrganizationForm;
