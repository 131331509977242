/*
long-term
Hvordan vil du rangere {1} sine faglige evner?
Hvordan vil du rangere {1} sine evner til å dele av sin kunnskap og erfaringer?
Hvordan vil du rangere {1} sitt bidrag til arbeidsmiljøet?
Hvordan vil du rangere {1} sine samarbeidsevner?

workshop
Hvordan vil du rangere {1} sin kunnskap og formidlingsevne innen emnet?
Hvor engasjerende og interaktivt opplevde du kurset/workshopen med {1}?
Hvor relevant og nyttig var innholdet i kurset/workshopen for dine behov?
Hvordan vil du rangere din samlede tilfredshet med kurset/workshopen levert av {1}?

delivery
Hvordan vil du rangere {1} sin faglige kompetanse og ekspertise?
Hvor fornøyd er du med {1} sin kommunikasjon og tilgjengelighet gjennom oppdraget?
Hvor godt opplevde du at {1} forstod og ivaretok dine behov og forventninger?
Hvordan vil du rangere den totale kvaliteten på tjenesten levert av {1}?
*/

import { SurveyType } from "../types";

const getQuestionsForSurveyType = (surveyType: SurveyType) => {
  switch (surveyType) {
    case 'workshop':
      return ['subjectKnowledge', 'engaging', 'relevance', 'satisfaction'];
    case 'delivery':
      return ['expertise', 'communication', 'expectations', 'quality'];
    case 'long-term':
    default:
      return ['knowledge', 'knowledgeSharing', 'influence', 'collaboration']
  };
};

export default getQuestionsForSurveyType;