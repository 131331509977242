import Section from "../Section";
import Surveys from "../Survey/Surveys";
import { useEffect, useState } from "react";
import { Card } from "../../types";
import { useApiData } from "../../contexts/ApiData";
import FeedbachCard from "../FeedbachCard/Index";
import CheckOrganization from "../../CheckOrganization";
import Recommendation from "./Recommendation";

const Dashboard = () => {
  const { get } = useApiData();
  const [ card, setCard ] = useState<Card | null>(null);
  useEffect(() => {
    const call = async () => {
      const card = await get(`/card?latest=true`);
      if (card) {
        setCard(card);
      }
    };
    call();
  }, [get]);

  return (
    <Section>
      <CheckOrganization />
      <Recommendation />
      { card && (
        <>
          <FeedbachCard card={card} />
        </>
      )}
      <Surveys limitFinished={1} />
    </Section>
  );
};

export default Dashboard;
