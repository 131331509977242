import { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import SystemMessage from '../components/SystemMessage/Index';
import { useApiData } from './ApiData';
import { Organization, Profile, SubscriptionData, Subscription, SubscriptionStatus, SubscriptionType } from '../types';

type OrganizationContextType = {
  organizations: Organization[] | null;
  currentOrganization: string;
  currentRole: string;
  setCurrentOrganization: (org: string) => void;
  profile?: Profile;
  role?: string;
  subscription: SubscriptionData;
}

const OrganizationContext = createContext<OrganizationContextType>({
  organizations: null,
  currentOrganization: '',
  currentRole: '',
  setCurrentOrganization: () => { },
  subscription: {
    status: SubscriptionStatus.None,
    type: SubscriptionType.None,
  }
});

export const useOrganizations = () => {
  const { organizations, subscription, currentRole, currentOrganization, setCurrentOrganization } = useContext(OrganizationContext);
  return { organizations, subscription, currentRole, currentOrganization, setCurrentOrganization };
};

export const useProfile = () => {
  const { profile } = useContext(OrganizationContext);
  return profile;
};

export const useRole = () => {
  const { role } = useContext(OrganizationContext);
  return role;
};

export const useSetCurrentOrganization = () => {
  const { currentOrganization, setCurrentOrganization } = useContext(OrganizationContext);
  return { currentOrganization, setCurrentOrganization };
};

type OrganizationProviderProps = {
  children: ReactNode;
};

const findCurrentSubscription = (subscriptions: Subscription[]): SubscriptionData => {
  const currentSub = subscriptions[0] || {};

  return currentSub
    ? ({
      id: currentSub.id,
      type: currentSub.type,
      status: currentSub.status,
      endDate: currentSub.endDate,
      startDate: currentSub.startDate,
    })
    : ({
      type: SubscriptionType.None,
      status: SubscriptionStatus.None,
    });
};

export const OrganizationProvider: React.FC<OrganizationProviderProps> = ({ children }) => {
  const { get } = useApiData();
  const [organizations, setOrganizations] = useState<Organization[] | null>(null);
  const [currentOrganization, setCurrentOrganization] = useState('');
  const [currentRole, setCurrentRole] = useState('');
  const [subscription, setSubscription] = useState<SubscriptionData>({} as SubscriptionData);
  const [profile, setProfile] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [role, setRole] = useState(undefined);

  useEffect(() => {
    const call = async () => {
      const { organization, profile, role } = await get('/user');
      if (organization) {
        const subscriptions = await get(`/subscriptions`);
        const subscription = findCurrentSubscription(subscriptions);
        setSubscription(subscription);
        setOrganizations([organization]);
        setCurrentOrganization(organization.id)
        setCurrentRole(role);
      }
      
      setIsLoading(false);

      setProfile(profile);
      setRole(role);
    }
    call();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <SystemMessage message="loading" />;
  }

  return (
    <OrganizationContext.Provider value={{ organizations, subscription, currentOrganization, currentRole, setCurrentOrganization, profile, role }}>
      {children}
    </OrganizationContext.Provider>
  );
};
