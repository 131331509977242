import OrganizationForm from "../Forms/OrganizationForm";
import Section from "../Section";

const RegisterOrganization = () => {
  return (
    <Section>
      <OrganizationForm />
    </Section>
  );
};

export default RegisterOrganization;
