import { useApiData } from '../../contexts/ApiData';
import { useTranslations } from '../../contexts/Translation';
import { useState } from 'react';
import { Box } from 'theme-ui';
import { v4 as uuid } from 'uuid';
import { Comment, ValidationError, ValidationResult } from '../../types';
import Input from './Input';
import ErrorText from './ErrorText';
import Button from '../Button';

type CommentFormData = Comment & {};

type ComponentProps = {
  surveyId?: string;
  goalId?: string;
  addComment: (c: Comment) => void;
};

const CommentForm = ({ surveyId, goalId, addComment }: ComponentProps)  => {
  const t = useTranslations('comments');
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const { post } = useApiData();
  const initialFormData = {
    id: uuid(),
    goalId: goalId || null,
    surveyId: surveyId || null,
    content: '',
  };
  const [formData, setFormData] = useState<CommentFormData>(initialFormData);

  const validateData = ({ content }: CommentFormData): ValidationResult => {
    const errors: ValidationError[] = [];
    if (!content) { errors.push({ name: 'content', message: t('fieldRequired')}) }

    return { errors: errors.length > 0 ? errors: undefined, success: errors.length === 0 };
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors(errors.filter(err => err.name !== e.target.name));
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationResult = validateData(formData);

    if (validationResult.success) {
      const url = goalId ? `/improvement-goal/${goalId}/comment` : `/survey/${surveyId}/comment`;
      const result = await post(url, formData);
      if (!result) {
        setErrors([{ name: 'form', message: t('serverError') }]);
      } else {
        addComment(result);
        setFormData({ ...initialFormData, id: uuid(), })
      }
    } else {
      setErrors(validationResult.errors || []);
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
      { errors.filter(e => e.name !== 'form').length > 0 && <ErrorText sx={{ mt: 32, fontSize: 24, }} text={t('formErrors')} /> }
      <ErrorText sx={{ mt: 32, fontSize: 24, }} error={errors.find(e => e.name === 'form')} />
      <Input
        error={errors.find(e => e.name === 'content')}
        type="textarea"
        name="content"
        handleChange={handleChange}
        label={t('addCommentHeading')}
        value={formData.content} />

      <Button>{t('submit')}</Button>
    </Box>
  );
};

export default CommentForm;
