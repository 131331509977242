import { useDateFormatter, useTranslations } from "../../contexts/Translation";
import { H2, H3 } from "../Headers";
import { Box } from "theme-ui";
import GoalConclusionForm from "../Forms/GoalConclusionForm";
import ConclusionFlag from "./ConclusionFlag";
import { InfoMessage } from "../InfoMessage/Index";
import QuestionScores from "./QuestionScores";
import { scoreResult2ScoresObject } from "../../lib/mapping";
import Link from "../Link";
import { CreateImprovementGoalLink } from "../Survey/CreateImprovementGoalLink";
import { CreateImprovementGoalLink as CreateOrgGoalLink} from "../OrgDashboard/CreateImprovementGoalLink";
import { useProfile, useRole } from "../../contexts/UserProvider";
import { useApiData } from "../../contexts/ApiData";
import { useEffect, useState } from "react";
import { Stats } from "../../types";
import { InfoItem } from "../Survey/InfoItem";

export const ConclusionPhaseComponent = ({ questions, isEdit = false, isOrgGoal = false, goal, improvementData, recentSurveys = [] }: { questions: string[]; isEdit: boolean; isOrgGoal?: boolean; goal: any; improvementData?: any; recentSurveys?: any[] }) => {
  const t = useTranslations('improvementGoals');
  const d = useDateFormatter();
  const profile = useProfile();
  const { get } = useApiData();
  const [ recentOrgGoalResults, setRecentOrgGoalResults ] = useState<Stats | null>(null);
  const role = useRole();

  useEffect(() => {
    if (isOrgGoal) {
      getStats();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!goal) {
    return null;
  }

  const getStats = async () => {
    const response = await get(`/organization/stats?fromDate=${goal.registered}&toDate=${goal.endDate}`);
    setRecentOrgGoalResults(response);
  };

  const oneWeek = (new Date(Date.now() + 7*24*3600*1000)).toISOString();
  const latestSurvey = recentSurveys[0];
  const scoresObj = latestSurvey ? scoreResult2ScoresObject(latestSurvey.answers) : null;
  return (
      <Box>
        {
          goal.endDate < oneWeek ? (<>
            { isEdit ? <GoalConclusionForm orgGoal={isOrgGoal ? goal : null} />
            : (<>
              <H2>{t('conclusionHeading')}
                  <ConclusionFlag isSuccess={goal.isSuccess} successLabel={t('success')} failureLabel={t('fail')} />
              </H2>
              <Box as="p" sx={{ whiteSpace: 'pre-wrap' }}>{goal.result || t('emptyConclusion')}</Box>
            </>)}
            { isOrgGoal ? (
              <>
                <H2>{t('orgRecentSurveyResults')}</H2>
                { recentOrgGoalResults && recentOrgGoalResults.total.surveys === 0 ?
                  <InfoMessage>{t('noRecentSuveys')}</InfoMessage>
                : (
                  <Box>
                    <H3>{t('orgResultsFromTo', d(recentOrgGoalResults?.total.startTime), d(recentOrgGoalResults?.total.endTime))}</H3>
                    <QuestionScores questions={questions} scores={scoreResult2ScoresObject(recentOrgGoalResults?.questions || [])} selected={goal.categories} compareTo={goal.scoreData} />
                    <InfoItem label={t('totalSurveys')} value={`${recentOrgGoalResults?.total.surveys}`} />
                    <InfoItem label={t('totalAnsvars')} value={`${recentOrgGoalResults?.total.answers}`} />
                  </Box>
                )}
                  <Box>
                    <H3>{t('orgOriginalResultsFromTo', d(goal.originalFrom), d(goal.originalTo))}</H3>
                    <QuestionScores questions={questions} scores={goal.scoreData} selected={goal.categories} />
                  </Box>
                  { ['supporter', 'owner'].includes(`${role}`) && <CreateOrgGoalLink from={goal.endDate} /> }
              </>
            ) : (<>
                { latestSurvey && <>
                  <H3><Link href={`/survey/${latestSurvey.id}`}>{t('latestSurvey')} {` - ${latestSurvey.surveyName}`}</Link>, {d(latestSurvey.startDate)} - {d(latestSurvey.endDate)} - {t(`status${latestSurvey.status}`)}</H3>
                  { scoresObj && <QuestionScores questions={questions} status={latestSurvey.status} scores={scoresObj} selected={goal.categories} compareTo={improvementData} /> }
                  { goal.isSuccess && goal.userId === profile?.userId && 
                    <CreateImprovementGoalLink text={t('createNewGoal')} surveyId={latestSurvey.id} />
                  }
                  </>}
                <H3>
                  <Link href={`/survey/${goal.originalSurvey}`}>
                    {t('originalSurvey')} {` - ${improvementData?.surveyName}`} {improvementData?.startDate && d(improvementData?.startDate)} - {improvementData?.endDate && d(improvementData?.endDate)} 
                  </Link>
                </H3>
                { improvementData && <QuestionScores questions={questions} scores={improvementData} selected={goal.categories} /> }
              </>)}
              </>)
        : <>
            <H2>{t('conclusionHeading')}</H2>
            <InfoMessage>{t('patienceConclusion')}</InfoMessage>
          </>
        }
      </Box>
  );
};

