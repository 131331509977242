
export const getQuoteName = (recommendationName: string | undefined, recommendationTitle: string | undefined, email: string | undefined) => {
  if (recommendationName && recommendationTitle) {
    return `${recommendationName}, ${recommendationTitle}`;
  }
  if (recommendationName) {
    return `${recommendationName}`;
  }
  return email;
};
