import { useEffect, useState } from "react";
import { Recommendation as RecommendationType } from "../../types";
import { useApiData } from "../../contexts/ApiData";
import Quote from "../Quote";
import { getQuoteName } from "../../lib/getQuoteName";
import { Box } from "theme-ui";
import { useTranslations } from "../../contexts/Translation";
import Link from "../Link";

const Recommendation = () => {
  const { get } = useApiData();
  const [ recommendations, setRecommendations ] = useState<RecommendationType[]>([]);
  const [highlighted, setHighlighted] = useState<RecommendationType | undefined>(undefined);
  const t = useTranslations('dashboard');
  useEffect(() => {
    const call = async () => {
      const recs = await get(`/recommendations`);
      if (recs) {
        setRecommendations(recs);
        setHighlighted(recs[Math.floor(Math.random() * recs.length)]);
      }
    };
    call();
  }, [get]);

  if (recommendations.length === 0) {
    return null;
  }

  return (
    <Box>
      { highlighted && (
        <Quote date={highlighted.respondedAt} from={getQuoteName(highlighted.recommendationName, highlighted.recommendationTitle, '')}>{highlighted.recommendation}</Quote>
      )}
      <Box as="p" sx={{ ml: 90, mt: 16 }}><Link href="/recommendations">{t('seeMoreRecommendations')}</Link></Box>
    </Box>
  );
};

export default Recommendation;
