import { FailIcon, SuccessIcon } from "../Icons"
import { Text } from 'theme-ui';

type ComponentProps = {
  isSuccess: string | undefined;
  successLabel?: string;
  failureLabel?: string;
}
const ConclusionFlag = ({ isSuccess, successLabel = '', failureLabel = '' }: ComponentProps) => {
  if (isSuccess === 'yes') {
    return <Text sx={{ display: 'inline-block', ml: 16, color: 'positive' }}>
            <SuccessIcon />{successLabel ? ` ${successLabel}` : ''}</Text>
  }
  if (isSuccess === 'no') {
    return <Text sx={{ display: 'inline-block', ml: 16, color: 'negative' }}>
            <FailIcon />{failureLabel ? ` ${failureLabel}` : ''}</Text>
  }
  return null;
};

export default ConclusionFlag;
