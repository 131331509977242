import { WithSx } from "../../types";
import { keyframes } from "@emotion/react";
import { Box, Donut, Flex, Text, ThemeUIStyleObject } from "theme-ui";

type ScoreProps = WithSx & {
  score?: number;
  status?: string;
  size?: number;
  inside?: string;
  after?: string;
  afterSx?: ThemeUIStyleObject;
};

const getColor = (score: number): string => {
  if (score === 0 || !score) {
    return 'unknown';
  }
  if (score < 4) {
    return 'negative';
  }
  if (score < 7) {
    return 'mediocre';
  }
  return 'positive';
};

const EmScore = ({ score = 0, status = 'finished', size = 100, inside, after, sx, afterSx }: ScoreProps) => {
  const fx = { animation: '', };
  if (status === 'ongoing') {
    fx.animation = `${keyframes({ from: { opacity: 1 }, to: { opacity: .6 } })} 1s infinite alternate`
  }

  const insideText = inside ? (
    <Box sx={{
      position: 'relative',
      fontFamily: 'heading',
      fontWeight: 'bold',
      textAlign: 'center',
      width: `${size / 2}em`,
      display: 'inline-block',
      ...sx
    }}>
      {inside && (
        <Text sx={{
          textTransform: 'uppercase',
          mb: '-0.75em',
          color: 'mutedText',
          fontSize: '0.5em',
          display: 'block' }}>
          {inside}
        </Text>
      )}
      <Text sx={{ fontSize: '2em'}}>
        {score > 0 ? (score === 10 && !isNaN(score)) ? score : score.toFixed(1) : '-'}
      </Text>
    </Box>)
    : (
      <Box sx={{ fontSize: `${size/3.5}em`, fontFamily: 'heading', fontWeight: 'bold', textAlign: 'center', width: `${size / 2}em`, display: 'inline-block', ...sx }}>
        {score > 0 ? (score === 10 && !isNaN(score)) ? score : score.toFixed(1) : '-'}
      </Box>)

  return (
    <Flex sx={{ position: 'relative', alignItems: 'center', justifyContent: 'center', height: `${size}em`, width: `${size*1.1}em`, ...sx }}>
      <Donut color={getColor(score)}
        value={score || 0} min={0} max={10}
        strokeWidth={3.5} size={`${size}em`} title={`${score}`}
        strokeDashoffset={0}
        sx={{ position: 'absolute', ...fx, }}
      />
      {insideText}
      {after && <Box sx={{ whiteSpace: 'pre', textAlign: 'center', fontFamily: 'interaction', fontSize: `${size / 6}em`, position: 'absolute', bottom: `-${size / 1.0}em`, ...afterSx }}>{after}</Box>}
    </Flex>
  );
};

export default EmScore;
