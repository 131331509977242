import { createContext, useContext, useState, useEffect, useCallback, ReactNode } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

type ApiFunctions = {
  get: Function;
  post: Function;
  put: Function;
  patch: Function;
  del: Function;
};

const ApiDataContext = createContext<ApiFunctions>({ get: Function.prototype, post: Function.prototype, put: Function.prototype, patch: Function.prototype, del: Function.prototype });

export const useApiData = () => useContext(ApiDataContext);

type ApiDataProviderProps = {
  children: ReactNode;
  isPublic?: boolean;
};

export const ApiDataProvider: React.FC<ApiDataProviderProps> = ({ children, isPublic = false }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [token, setToken] = useState<string | null>(null);

  const apiUrl = process.env.REACT_APP_API;

  const del = useCallback(async (endpoint: string) => {
    try {
      const response = await fetch(`${apiUrl}${endpoint}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        console.error(`API request failed: ${response.status}`);
        return false;
      }

      return response.json();
    } catch (ex) {
      console.error('API request failed', ex);
      return false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const get = useCallback(async (endpoint: string) => {
    try {
      const response = await fetch(`${apiUrl}${endpoint}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        console.error(`API request failed: ${response.status}`);
        return false;
      }

      return response.json();
    } catch (ex) {
      console.error('API request failed', ex);
      return false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const post = useCallback(async (endpoint: string, data: Object) => {
    try {
      const response = await fetch(`${apiUrl}${endpoint}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        console.error(`API request failed: ${response.status}`);
        return null;
      }

      return response.json();
    } catch (ex) {
      console.error('API request failed', ex);
      return null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const patch = useCallback(async (endpoint: string, data: Object) => {
    try {
      const response = await fetch(`${apiUrl}${endpoint}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        console.error(`API request failed: ${response.status}`);
        return null;
      }

      return response.json();
    } catch (ex) {
      console.error('API request failed', ex);
      return null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const put = useCallback(async (endpoint: string, data: Object) => {
    try {
      const response = await fetch(`${apiUrl}${endpoint}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        console.error(`API request failed: ${response.status}`);
        return null;
      }

      return response.json();
    } catch (ex) {
      console.error('API request failed', ex);
      return null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    const getToken = async () => {
      if (isAuthenticated) {
        try {
          const accessToken = await getAccessTokenSilently();
          setToken(accessToken);
        } catch (e) {
          console.error("Error getting access token", e);
        }
      }
    };
    if (!isPublic) {
      getToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!token && !isPublic) {
    return null;
  }

  return (
    <ApiDataContext.Provider value={{
      get,
      post,
      put,
      patch,
      del,
    }}>
      {children}
    </ApiDataContext.Provider>
  );
};
