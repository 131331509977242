import GraphicsProps from './GraphicProps';

export const Logo = ({ width = '300px', height = '166px', fillColor = 'white' }: GraphicsProps) => (
  <svg width={width} height={height} viewBox="0 0 800 431" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
  <path d="M10.1864 406.497C11.5446 401.244 12.8349 393.501 14.0572 383.267C15.4154 373.031 16.0945 361.315 16.0945 348.118C16.0945 334.92 15.4833 316.808 14.261 293.779H7.9454C2.64846 293.779 0 291.49 0 286.911C0 282.333 2.64846 280.042 7.9454 280.042C10.6617 280.042 12.699 280.11 14.0572 280.245C14.6005 263.413 20.9841 249.205 33.2076 237.622C45.5671 225.908 57.9267 220.049 70.286 220.049C75.1757 220.049 79.1822 220.925 82.3061 222.675C85.5657 224.426 87.1956 226.985 87.1956 230.35C87.1956 233.583 86.2448 236.142 84.3434 238.027C82.5777 239.778 80.8121 240.654 79.0464 240.654C77.2809 240.654 74.2249 239.913 69.8787 238.431C65.5324 236.815 61.2542 236.007 57.0438 236.007C48.6231 236.007 41.4247 239.71 35.4486 247.117C29.4727 254.389 26.4847 263.143 26.4847 273.377V281.054H33.004L61.1183 279.436C66.4153 279.436 69.0637 281.928 69.0637 286.911C69.0637 291.894 66.4153 294.385 61.1183 294.385L33.004 292.77C30.0159 292.77 27.8428 292.837 26.4847 292.971C26.7562 307.784 27.3675 324.416 28.3181 342.865C29.4048 361.315 29.948 379.496 29.948 397.407C29.948 419.222 25.6697 430.13 17.1131 430.13C11.1371 430.13 8.14912 426.293 8.14912 418.616C8.14912 415.922 8.8282 411.883 10.1864 406.497Z" fill={fillColor}/>
  <path d="M119.466 331.352L95.2222 329.331C92.9133 329.331 91.0796 329.534 89.7215 329.937C90.5364 337.614 93.7961 344.01 99.5005 349.128C105.341 354.246 112.2 356.805 120.077 356.805C127.683 356.805 134.542 354.043 140.654 348.522C142.827 346.636 144.456 345.695 145.543 345.695C146.63 345.695 147.58 346.098 148.395 346.906C149.21 347.713 149.617 348.589 149.617 349.531C149.617 353.437 145.747 358.285 138.005 364.076C130.399 369.866 121.707 372.763 111.928 372.763C102.285 372.763 93.8641 368.857 86.6657 361.047C79.6031 353.234 76.0717 343.539 76.0717 331.958C76.0717 317.144 81.7083 304.687 92.9812 294.588C104.254 284.487 116.682 279.437 130.263 279.437C135.968 279.437 140.586 280.448 144.117 282.468C147.784 284.352 149.617 286.843 149.617 289.941C149.617 292.905 148.667 295.329 146.765 297.214C145 299.098 143.234 300.042 141.468 300.042C139.703 300.042 136.647 299.233 132.301 297.618C128.09 296.001 124.016 295.194 120.077 295.194C112.743 295.194 106.359 297.415 100.927 301.86C95.6296 306.169 92.0983 311.555 90.3326 318.02L118.447 316.403C124.695 316.403 127.819 318.827 127.819 323.675C127.819 328.793 125.034 331.352 119.466 331.352Z" fill={fillColor}/>
  <path d="M202.23 331.352L177.987 329.331C175.678 329.331 173.844 329.534 172.486 329.937C173.301 337.614 176.56 344.01 182.265 349.128C188.105 354.246 194.964 356.805 202.841 356.805C210.448 356.805 217.307 354.043 223.418 348.522C225.59 346.636 227.221 345.695 228.307 345.695C229.393 345.695 230.345 346.098 231.159 346.906C231.974 347.713 232.381 348.589 232.381 349.531C232.381 353.437 228.512 358.285 220.769 364.076C213.163 369.866 204.47 372.763 194.692 372.763C185.049 372.763 176.628 368.857 169.43 361.047C162.367 353.234 158.836 343.539 158.836 331.958C158.836 317.144 164.473 304.687 175.745 294.588C187.018 284.487 199.446 279.437 213.029 279.437C218.731 279.437 223.35 280.448 226.881 282.468C230.548 284.352 232.381 286.843 232.381 289.941C232.381 292.905 231.431 295.329 229.53 297.214C227.764 299.098 225.999 300.042 224.232 300.042C222.466 300.042 219.411 299.233 215.064 297.618C210.854 296.001 206.781 295.194 202.841 295.194C195.507 295.194 189.124 297.415 183.691 301.86C178.394 306.169 174.863 311.555 173.097 318.02L201.212 316.403C207.46 316.403 210.582 318.827 210.582 323.675C210.582 328.793 207.799 331.352 202.23 331.352Z" fill={fillColor}/>
  <path d="M432.577 339.229C432.577 339.229 433.12 326.907 434.209 302.263C434.209 280.313 433.664 263.143 432.577 250.753C431.491 238.363 430.948 231.968 430.948 231.562C430.948 223.887 433.936 220.049 439.911 220.049C447.111 220.049 450.71 225.57 450.71 236.613C450.71 243.211 449.962 254.456 448.47 270.347C447.111 286.237 446.364 296.809 446.227 302.062C451.932 295.059 458.723 289.537 466.6 285.498C474.613 281.457 482.626 279.436 490.641 279.436C498.789 279.436 505.852 282.737 511.827 289.335C517.941 295.8 520.997 303.677 520.997 312.97C520.997 327.918 515.224 341.653 503.68 354.178C492.134 366.568 479.027 372.763 464.36 372.763C455.26 372.763 447.654 369.53 441.542 363.065C435.565 356.467 432.577 348.522 432.577 339.229ZM454.783 304.283C448.808 310.208 445.82 317.482 445.82 326.101C445.82 334.719 448.808 342.059 454.783 348.118C460.897 354.043 468.231 357.005 476.787 357.005C485.479 357.005 492.745 354.043 498.586 348.118C504.425 342.059 507.347 334.719 507.347 326.101C507.347 317.482 504.425 310.208 498.586 304.283C492.745 298.359 485.479 295.394 476.787 295.394C468.231 295.394 460.897 298.359 454.783 304.283Z" fill={fillColor}/>
  <path d="M604.008 344.483C604.957 346.906 606.927 350.205 609.915 354.381C613.039 358.42 614.602 361.72 614.602 364.277C614.602 366.836 613.922 368.924 612.564 370.54C611.205 372.022 609.372 372.763 607.063 372.763C601.495 372.763 596.537 367.039 592.191 355.593L590.56 351.148C583.635 365.557 573.243 372.763 559.391 372.763C552.464 372.763 546.691 370.607 542.074 366.298C537.592 361.853 535.35 356.332 535.35 349.734C535.35 339.5 538.882 330.678 545.944 323.271C553.007 315.865 562.243 311.893 573.652 311.355C570.935 306.64 567.879 303.139 564.483 300.85C561.225 298.426 558.237 297.012 555.52 296.608C552.803 296.203 550.222 296.001 547.777 296.001C545.469 295.867 543.635 295.329 542.277 294.385C540.918 293.444 540.241 291.626 540.241 288.931C540.241 286.104 541.327 283.813 543.499 282.063C545.81 280.313 548.525 279.437 551.649 279.437C560.477 279.437 569.17 283.813 577.726 292.567C586.282 301.321 593.752 315.056 600.136 333.776L604.008 344.483ZM568.559 322.059C563.669 322.059 559.255 323.675 555.316 326.907C551.512 330.14 549.611 334.314 549.611 339.432C549.611 344.55 551.31 348.859 554.704 352.36C558.237 355.726 562.447 357.411 567.336 357.411C572.225 357.411 576.435 355.726 579.966 352.36C583.499 348.859 585.264 344.751 585.264 340.038C585.264 336.67 584.653 333.573 583.431 330.746C582.208 327.783 581.461 325.966 581.19 325.292C577.658 323.136 573.448 322.059 568.559 322.059Z" fill={fillColor}/>
  <path d="M668.586 295.194C659.621 295.194 652.287 298.291 646.582 304.486C641.013 310.546 638.23 317.818 638.23 326.301C638.23 334.785 641.15 341.991 646.991 347.916C652.966 353.84 660.232 356.805 668.79 356.805C676.394 356.805 683.253 354.043 689.365 348.522C691.539 346.636 693.168 345.695 694.256 345.695C695.342 345.695 696.292 346.098 697.107 346.906C697.923 347.713 698.33 348.589 698.33 349.531C698.33 353.437 694.458 358.285 686.718 364.076C679.111 369.866 670.419 372.763 660.641 372.763C650.996 372.763 642.576 368.857 635.379 361.047C628.315 353.234 624.785 343.539 624.785 331.958C624.785 317.144 630.421 304.687 641.693 294.588C652.966 284.487 665.394 279.437 678.975 279.437C684.68 279.437 689.299 280.448 692.829 282.468C696.496 284.352 698.33 286.843 698.33 289.941C698.33 292.905 697.38 295.329 695.478 297.214C693.711 299.098 691.946 300.042 690.18 300.042C688.415 300.042 685.359 299.233 681.013 297.618C676.803 296.001 672.659 295.194 668.586 295.194Z" fill={fillColor}/>
  <path d="M791.939 342.665C792.754 346.569 794.519 350.071 797.237 353.167C800.088 356.131 801.515 359.161 801.515 362.258C801.515 365.356 800.429 367.915 798.255 369.933C796.082 371.819 793.706 372.763 791.125 372.763C788.544 372.763 786.438 372.29 784.809 371.348C783.18 370.404 781.822 368.992 780.735 367.106C779.241 364.277 778.086 360.844 777.271 356.805L768.308 314.585C766.949 308.39 764.775 303.475 761.788 299.839C758.936 296.203 754.454 294.385 748.342 294.385C742.365 294.385 737.68 296.473 734.284 300.648C731.026 304.687 729.326 309.131 729.192 313.979C730.278 345.895 730.821 362.797 730.821 364.682C730.821 366.433 730.006 368.251 728.377 370.136C726.747 371.886 724.641 372.763 722.061 372.763C719.48 372.763 717.239 371.886 715.338 370.136C713.573 368.251 712.689 365.221 712.689 361.046C712.689 356.87 713.029 346.974 713.709 331.352C714.388 315.596 714.727 303.407 714.727 294.788C714.727 286.17 714.388 274.589 713.709 260.045C713.029 245.502 712.689 236.142 712.689 231.968C712.689 227.791 713.573 224.762 715.338 222.876C717.239 220.991 719.48 220.049 722.061 220.049C724.641 220.049 726.747 220.991 728.377 222.876C730.006 224.628 730.821 227.591 730.821 231.765C730.821 235.804 730.483 245.367 729.803 260.449C729.26 275.397 728.988 287.652 728.988 297.214C737.001 285.363 746.236 279.436 756.694 279.436C770.955 279.436 780.122 288.931 784.198 307.919L791.939 342.665Z" fill={fillColor}/>
  <path d="M302.686 380.436C333.47 376.506 356.382 339.088 352.388 302.118C352.388 237.062 352.388 171.915 352.388 106.903C354.478 104.001 358.77 110.163 360.97 110.475C385.747 128.336 407.65 157.225 409.143 193.481C410.784 221.789 403.659 249.519 395.636 275.773C422.464 235.989 431.085 177.943 412.427 130.925C402.875 103.644 381.383 86.8101 369.33 61.493C360.099 44.6489 354.037 25.6043 351.568 5.67922C349.815 1.52667 345.485 1.21411 342.091 1.21411H342.016C331.306 1.21411 332.761 9.74245 332.761 18.4048V264.923C332.761 264.923 327.202 264.299 323.097 264.299C291.717 261.841 257.649 289.078 254.515 328.461C250.858 359.717 278.246 385.301 302.686 380.436Z" fill={fillColor}/>
  <path d="M353.676 268.696C371.466 293.455 362.874 336.078 334.486 363.9C306.098 391.719 268.664 394.2 250.876 369.441C233.088 344.681 241.681 302.058 270.068 274.239C298.456 246.418 335.889 243.936 353.676 268.696Z" fill={fillColor}/>
  </g>
  </svg>
  );

  export const Note = ({ width = '100px', height = '230px', fillColor = 'white' }: GraphicsProps) => (
    <svg width={width} height={height} viewBox="0 0 200 430" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M67.5674 423.113C101.623 418.729 126.969 376.981 122.552 335.731C122.552 263.144 122.552 190.458 122.552 117.922C124.864 114.683 129.611 121.558 132.047 121.907C159.457 141.835 183.688 174.068 185.339 214.521C187.155 246.106 179.271 277.044 170.396 306.337C200.076 261.948 209.612 197.184 188.972 144.724C178.404 114.285 154.627 95.5031 141.293 67.2557C131.081 48.462 124.376 27.2133 121.644 4.9819C119.704 0.348727 114.916 0 111.159 0H111.076C99.2291 0 100.839 9.51543 100.839 19.1803V294.231C100.839 294.231 94.6883 293.534 90.1475 293.534C55.4311 290.794 17.7425 321.183 14.275 365.124C10.2295 399.997 40.529 428.543 67.5674 423.113Z" fill={fillColor}/>
  <path d="M123.978 298.441C143.656 326.066 134.151 373.622 102.746 404.662C71.3409 435.702 29.9294 438.471 10.2505 410.846C-9.42847 383.221 0.0772209 335.664 31.4821 304.625C62.8869 273.585 104.299 270.816 123.978 298.441Z" fill={fillColor}/>
  <path d="M67.5674 423.113C101.623 418.729 126.969 376.981 122.552 335.731C122.552 263.144 122.552 190.458 122.552 117.922C124.864 114.683 129.611 121.558 132.047 121.907C159.457 141.835 183.688 174.068 185.339 214.521C187.155 246.106 179.271 277.044 170.396 306.337C200.076 261.948 209.612 197.184 188.972 144.724C178.404 114.285 154.627 95.5031 141.293 67.2557C131.081 48.462 124.376 27.2133 121.644 4.9819C119.704 0.348727 114.916 0 111.159 0H111.076C99.2291 0 100.839 9.51543 100.839 19.1803V294.231C100.839 294.231 94.6883 293.534 90.1475 293.534C55.4311 290.794 17.7425 321.183 14.275 365.124C10.2295 399.997 40.529 428.543 67.5674 423.113Z" fill={fillColor}/>
  <path d="M123.978 298.441C143.656 326.066 134.151 373.622 102.746 404.662C71.3409 435.702 29.9294 438.471 10.2505 410.846C-9.42847 383.221 0.0772209 335.664 31.4821 304.625C62.8869 273.585 104.299 270.816 123.978 298.441Z" fill={fillColor}/>
  <path d="M67.5674 423.113C101.623 418.729 126.969 376.981 122.552 335.731C122.552 263.144 122.552 190.458 122.552 117.922C124.864 114.683 129.611 121.558 132.047 121.907C159.457 141.835 183.688 174.068 185.339 214.521C187.155 246.106 179.271 277.044 170.396 306.337C200.076 261.948 209.612 197.184 188.972 144.724C178.404 114.285 154.627 95.5031 141.293 67.2557C131.081 48.462 124.376 27.2133 121.644 4.9819C119.704 0.348727 114.916 0 111.159 0H111.076C99.2291 0 100.839 9.51543 100.839 19.1803V294.231C100.839 294.231 94.6883 293.534 90.1475 293.534C55.4311 290.794 17.7425 321.183 14.275 365.124C10.2295 399.997 40.529 428.543 67.5674 423.113ZM123.978 298.441C143.656 326.066 134.151 373.622 102.746 404.662C71.3409 435.702 29.9294 438.471 10.2505 410.846C-9.42847 383.221 0.0772209 335.664 31.4821 304.625C62.8869 273.585 104.299 270.816 123.978 298.441Z" stroke={fillColor}/>
    </svg>
    );

export const LogoBlack = () => Logo({ fillColor: 'black' });

export default Logo;


