import Main from './components/Main';
import ResponseForm from './components/Forms/ResponderForm';
import { Box } from 'theme-ui';
import LogoBlack from './components/Icons/Logo';

const ResponseApp = () => {
  return (
    <Main>
      <Box sx={{
        background: 'secondaryBackground',
        my: 0,
        borderBottom: '1px solid #333',
        p: 8,
        textAlign: 'center',
      }}>
        <a href="https://feedbach.me"><LogoBlack height="50" width="135" fillColor="#212" /></a>
      </Box>
      <ResponseForm />
    </Main>
  );
};

export default ResponseApp;
