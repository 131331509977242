import { useDateFormatter, useTranslations } from "../../contexts/Translation";
import { H2, H3 } from "../Headers";
import { Box } from "theme-ui";
import { ImprovementSurvey, Stats } from "../../types";
import QuestionScores from "./QuestionScores";
import { scoreResult2ScoresObject } from "../../lib/mapping";
import Link from "../Link";
import { InfoMessage } from "../InfoMessage/Index";
import Comments from "../Comments/List";
import { useApiData } from "../../contexts/ApiData";
import { useEffect, useState } from "react";
import { InfoItem } from "../Survey/InfoItem";

export const ExecutionPhaseComponent = ({ questions, isOrgGoal = false, goal, improvementData, recentSurveys = [] }: { questions: string[]; isOrgGoal?: boolean; goal: any; improvementData?: any; recentSurveys?: any[] }) => {
  const t = useTranslations('improvementGoals');
  const d = useDateFormatter();
  const { get } = useApiData();
  const [ recentOrgGoalResults, setRecentOrgGoalResults ] = useState<Stats | null>(null);

  const getStats = async () => {
    const response = await get(`/organization/stats?fromDate=${goal.registered.substring(0, 10)}&toDate=${goal.endDate}&surveyType=${goal.surveyType}`);
    setRecentOrgGoalResults(response);
  };

  useEffect(() => {
    if (isOrgGoal) {
      getStats();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!goal) {
    return null;
  }

  return (
      <Box>
        <H2>{t('journalHeading')}</H2>
        <Comments goalId={goal.id} />
        { isOrgGoal ? (
          <>
            <H2>{t('orgRecentSurveyResults')}</H2>
            { recentOrgGoalResults && recentOrgGoalResults.total.surveys === 0 ?
              <InfoMessage>{t('noRecentSuveys')}</InfoMessage>
            : (
              <Box>
                <H3>{t('orgResultsFromTo', d(recentOrgGoalResults?.total.startTime), d(recentOrgGoalResults?.total.endTime))}</H3>
                <QuestionScores questions={questions} scores={scoreResult2ScoresObject(recentOrgGoalResults?.questions || [])} selected={goal.categories} compareTo={goal.scoreData} />
                <InfoItem label={t('totalSurveys')} value={`${recentOrgGoalResults?.total.surveys}`} />
                <InfoItem label={t('totalAnsvars')} value={`${recentOrgGoalResults?.total.answers}`} />
              </Box>
            )}
              <Box>
                <H3>{t('orgOriginalResultsFromTo', d(goal.originalFrom), d(goal.originalTo))}</H3>
                <QuestionScores questions={questions} scores={goal.originalScores} selected={goal.categories} />
              </Box>
          </>
        ): (
          <>
            <H2>{t('latestSurveys')}</H2>
            { recentSurveys.length === 0 ? (
              <InfoMessage>{t('noRecentSuveys')} <Link href="/survey">{t('createSurvey')}</Link></InfoMessage>
            )
            : (recentSurveys.map((s: ImprovementSurvey) => {
              const scoreObj = scoreResult2ScoresObject(s.answers);
              return (
                <Box key={s.id}>
                  <H3><Link href={`/survey/${s.id}`}>{s.surveyName}</Link>, {d(s.startDate)} - {d(s.endDate)} - {t(`status${s.status}`)}</H3>
                  <QuestionScores questions={questions} status={s.status} scores={scoreObj} selected={goal.categories} compareTo={improvementData} />
                </Box>
              );
            }))}
            <H3>
              <Link href={`/survey/${goal.originalSurvey}`}>
                {t('originalSurvey')} {` - ${improvementData?.surveyName}`} {improvementData?.startDate && d(improvementData?.startDate)} - {improvementData?.endDate && d(improvementData?.endDate)} 
              </Link>
            </H3>
            { improvementData && <QuestionScores questions={questions} scores={improvementData} selected={goal.categories} />}
          </>
        )}
      </Box>
  );
};

