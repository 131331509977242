import { useApiData } from '../../contexts/ApiData';
import { useTranslations } from '../../contexts/Translation';
import { useState, useEffect, useCallback, useReducer, useMemo } from 'react';
import { Box } from 'theme-ui';
import { H1 } from '../Headers';
import Section from '../Section';
import SystemMessage from '../SystemMessage/Index';
import { ResponderData, ValidationError, ValidationResult } from '../../types';
import Answer from '../Answer/Index';
import Input from './Input';
import Button from './Button';
import ErrorText from './ErrorText';
import Logo from '../Icons/Logo';
import Link from '../Link';
import { SmileyFaceRating } from '../SmileyFaceRating/SmileyFaceRating';

// Define SurveyType and Question
type SurveyType = 'long-term' | 'delivery' | 'workshop';

interface Question {
  name: string;
  label: string;
}

const surveyQuestions: Record<SurveyType, Question[]> = {
  'long-term': [
    { name: 'knowledge', label: 'Knowledge' },
    { name: 'knowledgeSharing', label: 'Knowledge Sharing' },
    { name: 'influence', label: 'Influence' },
    { name: 'collaboration', label: 'Collaboration' },
  ],
  'delivery': [
    { name: 'expertise', label: 'Expertise' },
    { name: 'communication', label: 'Communication' },
    { name: 'expectations', label: 'Expectations' },
    { name: 'quality', label: 'Quality' },
  ],
  'workshop': [
    { name: 'subjectKnowledge', label: 'Subject Knowledge' },
    { name: 'engaging', label: 'Engaging' },
    { name: 'relevance', label: 'Relevance' },
    { name: 'satisfaction', label: 'Satisfaction' },
  ],
};

// Define ResponseFormData
type ResponseFormData = {
  // Long-term
  knowledge?: number | null;
  knowledgeSharing?: number | null;
  influence?: number | null;
  collaboration?: number | null;

  // Delivery
  expertise?: number | null;
  communication?: number | null;
  expectations?: number | null;
  quality?: number | null;

  // Workshop
  subjectKnowledge?: number | null;
  engaging?: number | null;
  relevance?: number | null;
  satisfaction?: number | null;

  // Common fields
  recommendation?: string;
  message?: string;
  duration: number;
  giveRecommendation: boolean;
  recommendationName?: string;
  recommendationTitle?: string;
};

// Initialize the form state dynamically
const initializeFormData = (questions: Question[]): ResponseFormData => {
  const initialData: ResponseFormData = {
    duration: -1,
    giveRecommendation: false,
    message: '',
    recommendation: '',
    recommendationName: '',
    recommendationTitle: '',
  };

  questions.forEach(q => {
    initialData[q.name as keyof Omit<ResponseFormData, 'duration' | 'giveRecommendation' | 'message' | 'recommendation' | 'recommendationName' | 'recommendationTitle'>] = null;
  });

  return initialData;
};

// Reducer and initial state definitions
type State = ResponseFormData & { errors: ValidationError[]; isDone: boolean, hasResponded: boolean, hasRated: boolean };
type Action =
  | { type: 'SET_FIELD'; field: keyof ResponseFormData; value: any }
  | { type: 'SET_ERRORS'; errors: ValidationError[] }
  | { type: 'SET_IS_DONE'; isDone: boolean }
  | { type: 'SET_ALREADY_RESPONDED'; hasResponded: boolean }
  | { type: 'SET_HAS_RATED'; hasRated: boolean }
  | { type: 'RESET_FORM'; formData: ResponseFormData };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value };
    case 'SET_ERRORS':
      return { ...state, errors: action.errors };
    case 'SET_IS_DONE':
      return { ...state, isDone: action.isDone };
    case 'SET_ALREADY_RESPONDED':
      return { ...state, hasResponded: action.hasResponded };
    case 'SET_HAS_RATED':
      return { ...state, hasRated: action.hasRated };
    case 'RESET_FORM':
      return { ...action.formData, errors: [], isDone: false, hasResponded: false, hasRated: false };
    default:
      return state;
  }
};

const ResponseForm = () => {
  const { get, post } = useApiData();
  const [responderData, setResponderData] = useState<ResponderData | null>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [startingTime, setStartingTime] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [state, dispatch] = useReducer(reducer, {
    // Initial values are overridden after fetching data
    duration: -1,
    giveRecommendation: false,
    message: '',
    recommendation: '',
    recommendationName: '',
    recommendationTitle: '',
    errors: [],
    isDone: false,
    hasResponded: false,
    hasRated: false,
  });
  const t = useTranslations('responderForm');
  const id = window.location.pathname.split('/')?.[2];

  // Define the validation function
  const validateData = useCallback(
    (
      data: ResponseFormData,
      questions: Question[]
    ): ValidationResult => {
      const errors: ValidationError[] = [];

      const isValidScore = (score: number | null | undefined) =>
        typeof score === 'number' && score >= 0 && score <= 10;

      // Validate each question
      questions.forEach(question => {
        const value = data[question.name as keyof Omit<ResponseFormData, 'duration' | 'giveRecommendation' | 'message' | 'recommendation' | 'recommendationName' | 'recommendationTitle'>];
        if (!isValidScore(value)) {
          errors.push({
            name: question.name,
            message: t('allQuestionsRequired'),
          });
        }
      });

      return {
        errors: errors.length > 0 ? errors : undefined,
        success: errors.length === 0,
      };
    },
    [t]
  );

  useEffect(() => {
    setStartingTime(Date.now());

    const fetchData = async () => {
      try {
        const result = await get(`/respond/${id}`);
        if (result) {
          if (result.status === 'responded') {
            dispatch({ type: 'SET_ALREADY_RESPONDED', hasResponded: true });
          } else {
            setResponderData(result);

            // Initialize formData based on surveyType
            const surveyType: SurveyType = result.surveyType || 'long-term';
            const questions = surveyQuestions[surveyType];
            const initialFormData = initializeFormData(questions);
            dispatch({ type: 'RESET_FORM', formData: initialFormData });
          }
        } else {
          setNotFound(true);
        }
      } catch (error) {
        setNotFound(true);
        console.error('Fetch error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchData();
    } else {
      setNotFound(true);
      setIsLoading(false);
    }
  }, [get, id]);

  // Determine current questions based on surveyType
  const currentQuestions: Question[] = useMemo(() => {
      return responderData
        ? surveyQuestions[responderData.surveyType as SurveyType] || surveyQuestions['long-term']
        : [];
    }, [responderData]);

  const getValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.type === 'checkbox') {
      // @ts-ignore
      return e.target.checked ? e.target.value : false;
    }
    return e.target.value;
  }

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch({
        type: 'SET_FIELD',
        field: e.target.name as keyof ResponseFormData,
        value: getValue(e),
      });
    },
    []
  );

  const handleAnswer = useCallback(
    (question: keyof ResponseFormData, value: string) => {
      const parsedValue = parseInt(value, 10);
      if (!isNaN(parsedValue)) {
        dispatch({
          type: 'SET_FIELD',
          field: question,
          value: parsedValue,
        });
        // Remove existing error for the question
        const newErrors = state.errors.filter(e => e.name !== question);
        dispatch({ type: 'SET_ERRORS', errors: newErrors });
      }
    },
    [state.errors]
  );

  const saveSmileyFaceRating = useCallback(
    async (rating: number) => {
      const result = await post(`/respond/${id}/rating`, { rating });
      if (result) {
        dispatch({ type: 'SET_HAS_RATED', hasRated: true });
      }
    }, [id, post]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      const validationResult = validateData(state, currentQuestions);

      if (validationResult.success) {
        const duration = Math.round((Date.now() - startingTime) / 1000);

        try {
          const result = await post(`/respond/${id}`, {
            ...state,
            duration,
          });

          if (!result) {
            throw new Error('No response from server');
          }

          dispatch({ type: 'SET_IS_DONE', isDone: true });
        } catch (error) {
          dispatch({
            type: 'SET_ERRORS',
            errors: [{ name: 'form', message: t('serverError') }],
          });
          window.scrollTo({ top: 0, behavior: 'smooth' });
          console.error('Submission error:', error);
        }
      } else {
        dispatch({ type: 'SET_ERRORS', errors: validationResult.errors || [] });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
    [validateData, state, post, id, startingTime, t, currentQuestions]
  );

  if (isLoading) {
    return <SystemMessage message="loading" />;
  }

  if (state.hasResponded) {
    return <SystemMessage isError message="alreadyResponded" />;
  }

  if (notFound || !responderData) {
    return <SystemMessage isError message="notFound" />;
  }

  if (new Date(responderData.endDate) < new Date()) {
    return <SystemMessage isError message="surveyEnded" />;
  }

  if (state.isDone) {
    return (
      <Section>
        <Box
          sx={{
            mt: 64,
            borderRadius: '20px',
            backgroundColor: 'primary',
            mx: [16, 64],
            pt: 24,
            px: [16, 48],
            pb: 48,
          }}
        >
          <H1 sx={{ textAlign: 'center' }}>{t('thankYouHeading')}</H1>
          <Box sx={{ fontSize: 24, lineHeight: 1.6 }} as="p">
            {t('receiptText', (responderData?.candidate))}
          </Box>
          <SmileyFaceRating onRated={saveSmileyFaceRating} />
          <Box sx={{ textAlign: 'center', mt: 48 }}>
            <Link href="https://feedbach.me">
              <Logo height="75" width="202" fillColor="#212" />
            </Link>
          </Box>
        </Box>
      </Section>
    );
  }

  return (
    <Section>
        <Box sx={{ border: '1px solid #441144', borderRadius: '5px' }}>
          <H1 sx={{ textAlign: 'center', fontSize: 34 }}>
            {t('title')}
            <br />
            {responderData?.candidate}
          </H1>
          { responderData?.invitationText && <Box as="p" sx={{ whiteSpace: 'pre-wrap', p: 16 }}>
            {responderData?.invitationText}
          </Box>}
          <Box as="p" sx={{ borderTop: '1px solid #441144', fontStyle: 'italic', p: 16 }}>
            {t('responsesNotAnonymous', responderData?.candidate)}
          </Box>
        </Box>
      <Box as="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
        {state.errors.filter(e => e.name !== 'form').length > 0 && (
          <ErrorText
            sx={{ mt: 32, fontSize: 24 }}
            text={t('formErrors')}
          />
        )}
        <ErrorText
          sx={{ mt: 32, fontSize: 24 }}
          error={state.errors.find(e => e.name === 'form')}
        />

        {/* Dynamically render questions based on surveyType */}
        {currentQuestions.map(question => (
          <Answer
            key={question.name}
            error={state.errors.find(e => e.name === question.name)}
            question={question.name}
            onChange={handleAnswer}
            value={state[question.name as keyof Omit<ResponseFormData, 'duration' | 'giveRecommendation' | 'message' | 'recommendation' | 'recommendationName' | 'recommendationTitle'>]}
            candidate={responderData?.candidate}
          />
        ))}

        {/* Static fields */}
        <Input
          type="textarea"
          name="message"
          helpText={t('help.responderMessage')}
          handleChange={handleChange}
          label={t('messageLabel')}
          value={state.message}
        />

        <Input
          type="checkbox"
          error={state.errors.find(e => e.name === 'giveRecommendation')}
          name="giveRecommendation"
          handleChange={handleChange}
          label={t('giveRecommendation', responderData?.candidate)}
          value={state.giveRecommendation}
          infoText={t('recommendationInfo', responderData?.candidate)}
        />

        {state.giveRecommendation && (
          <>
            <Input
              type="textarea"
              name="recommendation"
              handleChange={handleChange}
              label={t('recommendationLabel')}
              value={state.recommendation}
            />

            <Input
              infoText={t('recommendationNameInfo')}
              name="recommendationName"
              helpText={t('help.recommendationName')}
              handleChange={handleChange}
              label={t('recommendationName')}
              value={state.recommendationName}
            />

            <Input
              name="recommendationTitle"
              handleChange={handleChange}
              helpText={t('help.recommendationTitle')}
              label={t('recommendationTitle')}
              value={state.recommendationTitle}
            />
          </>
        )}

        <Button
          sx={{
            color: 'mainText',
            backgroundColor: 'secondaryBackground',
            mx: 'auto',
            display: 'block',
            width: ['90%', '200px'],
          }}
        >
          {t('submit')}
        </Button>
      </Box>
    </Section>
  );
};

export default ResponseForm;
