import { useState } from 'react';
import { Box } from 'theme-ui';
import DepartmentsForm from '../DepartmentsForm';
import GroupsForm from '../GroupsForm';
import InviteSection from './InviteSection';
import UserSection from './UserSection';
import { UserManagementProvider } from './UserManagementContext';
import GroupsSection from './GroupsSection';
import DepartmentsSection from './DepartmentsSection';

const UserManagementComponent = () => {
  const [ showDepartments, setShowDepartments ] = useState(false);
  const [ showGroups, setShowGroups ] = useState(false);

  if (showDepartments) {
    return <DepartmentsForm closeFn={() => setShowDepartments(false)} />
  }

  if (showGroups) {
    return <GroupsForm closeFn={() => setShowGroups(false)} />
  }

  return (
    <Box sx={{ p: 3 }}>
      <InviteSection />
      <UserSection />
      <GroupsSection setShowGroups={setShowGroups} />
      <DepartmentsSection setShowDepartments={setShowDepartments} />
    </Box>
  );
};

const UserManagement = ()  => {
  return (
    <UserManagementProvider>
      <UserManagementComponent />
    </UserManagementProvider>
  );
};

export default UserManagement;
