import { useCallback, useState } from "react";
import { useTranslations } from "../../contexts/Translation";
import { Box, Button } from "theme-ui";

type ComponentProps = {
  onRated: (rating: number) => void;
};

export const SmileyFaceRating = ({ onRated }: ComponentProps) => {
  const t = useTranslations('smileyFaceRating');
  const [showReceipt, setShowReceipt] = useState(false);
  const handleClick = useCallback((rating: number) => {
    onRated(rating);
    setShowReceipt(true);
  }, [onRated]);
  return (
    <Box sx={{ border: '1px solid #441144', borderRadius: '5px', p: 16, mt: 32 }}>
      {t('ratingInfo')}
      { showReceipt ? <p>{t('smileyFaceRating.receipt')}</p> : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '150px', textAlign: 'center' }}>
          <Button variant="invisible" sx={{ cursor: 'pointer', fontSize: 32, mx: 2 }} onClick={() => handleClick(1)}>😡</Button>
          <Button variant="invisible" sx={{ cursor: 'pointer', fontSize: 32, mx: 2 }} onClick={() => handleClick(2)}>😐</Button>
          <Button variant="invisible" sx={{ cursor: 'pointer', fontSize: 32, mx: 2 }} onClick={() => handleClick(3)}>😍</Button>
        </Box>
      )}
    </Box>
  );
};