import { useApiData } from "../../contexts/ApiData";
import { useEffect, useState } from "react";
import { Box } from "theme-ui";
import { Comment } from '../../types';
import CommentForm from "../Forms/CommentForm";
import { useDateFormatter } from "../../contexts/Translation";

type ComponentProps = {
  goalId?: string;
  surveyId?: string;
}

const Comments = ({ goalId, surveyId }: ComponentProps) => {
  const d = useDateFormatter();
  const [comments, setComments] = useState<Comment[]>([]);
  const { get } = useApiData();

  useEffect(() => {
    const getComments = async () => {
      const url = !!goalId ? `/improvement-goal/${goalId}/comment` : `/survey/${surveyId}/comment`;
      const comments = await get(url);
      if (comments) {
        setComments(comments);
      }
    };
    getComments();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const addComment = (newComment: Comment) => {
    setComments([...comments, newComment]);
  };

  return (
    <Box>
      { comments.map(c => {
        return (
          <Box key={c.id} sx={{ borderStyle: 'solid', borderWidth: '1px', borderColor: 'muted', p: 16, borderRadius: 8, my: 16,  }}>
            <Box as="p" sx={{ whiteSpace: 'pre-wrap' }}>
              {c.content}
            </Box>
            <Box as="p" sx={{ fontFamily: 'interaction', textAlign: 'right', mt: 8, }}>
              {c.name} {c.registered ? d(c.registered, true) : ''}
            </Box>
          </Box>
        );
      })}
      <CommentForm addComment={addComment} goalId={goalId} surveyId={surveyId} />
    </Box>
  )

};

export default Comments;
