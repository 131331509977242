import { useApiData } from "../../contexts/ApiData";
import Section from "../Section";
import { Card } from "../../types";
import { useEffect, useState } from "react";
import FeedbachCard from "./Index";
import { Box } from "theme-ui";
import { Plus } from "../Icons";
import { useTranslations } from "../../contexts/Translation";
import { useNavigate } from "react-router-dom";
import SystemMessage from '../SystemMessage/Index';
import { InfoMessage } from "../InfoMessage/Index";
import { useOrganizations } from "../../contexts/UserProvider";

const CardRouteHandler = ({ isShared = false }) => {
  const { get } = useApiData();
  const t = useTranslations('cardForm');
  const [ cards, setCards ] = useState<Card[]>([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { currentOrganization } = useOrganizations();

  useEffect(() => {
    const callOwn = async () => {
      const cards = await get(`/card`);
      if (cards) {
        setCards(cards);
        setIsLoading(false);
      }
    };
    const callShared = async () => {
      const cards = await get(`/shared-card`);
      setCards(cards);
      setIsLoading(false);
    }
    if (isShared) {
      callShared();
    } else {
      callOwn();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <SystemMessage message="loading" />
  }

  return (
    <Section>
      { !isShared && (
        !currentOrganization && cards.length > 0 ? <InfoMessage>{t('onlyOneCard')}</InfoMessage> :
        <Box
          sx={{
            borderWidth: '3px',
            borderRadius: '10px',
            borderStyle: 'dashed',
            color: 'mutedText',
            py: 32,
            px: 32,
            m: 0,
            mb: 16,
            cursor: 'pointer',
            textAlign: 'center',
            fontSize: 32,
          }}
          onClick={() => navigate(`/card`)}>
          <Plus width='64px' height='64px' /> {t('createCard')}
        </Box>)}
      { cards.length === 0 && <InfoMessage>{t(isShared ? 'noSharedCards' : 'noCards')}</InfoMessage>}
      { cards.map(c => <FeedbachCard key={c.cardId} card={c} /> )}
    </Section>
  );
};

export default CardRouteHandler;
