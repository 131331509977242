import Section from '../Section';
import { Box, } from 'theme-ui';
import { H1 } from '../Headers';
import LogoBlack from '../Icons/Logo';
import Link from '../Link';
import HamburgerMenu from '../HamburgerMenu/Index';
import { useTranslations } from '../../contexts/Translation';
import { PopupMenu } from './PopupMenu';
import { FeedbachCard, Improvement, OrgDash, Survey } from '../Icons';
import { useOrganizations } from '../../contexts/UserProvider';

const NavItem = ({ href, text, sx, icon }: any) => (
  <Link sx={{ textDecoration: 'none', fontFamily: 'interaction', ':hover': { textDecoration: 'underline' }, color: 'mainText', display: 'block', textAlign: 'left', fontWeight: '400', ...sx }} href={href}>{ icon } {text}</Link>
);

const HeadingSection = ({ title }: any) => {
  const t = useTranslations('menu');
  const { currentRole } = useOrganizations();

  return (
    <Box sx={{
      background: 'secondaryBackground',
      my: 0,
      borderBottom: '1px solid #bbb',
      borderColor: 'outline',
    }}>
      <Section sx={{
        py: 8,
        my: 0,
        display: 'flex',
        alignItems: 'flex-end'
      }}>
        <H1 sx={{
          height: '50px',
          fontSize: 24, '> span': { display: 'none' },
          width: '140px',
          textAlign: 'left',
          my: 0,
          bg: 'primary', // Example background color
          // Add more styling as needed
          }}>
          <Link sx={{ p: 0 }} href="/">
            <LogoBlack height="50" width="135" fillColor="#212" />
          </Link>
          <span>feedbach</span>
        </H1>
        <Box sx={{ flex: 1, display: 'flex', textAlign: 'center', alignItems: 'flex-end', mb: '2px', ml: '1rem' }}>
          <PopupMenu text={t('myFeedbach')}>
            <>
              <NavItem href="/surveys" text={t('surveys')} icon={<Survey />}/>
              <NavItem href="/improvements" text={t('goals')} icon={<Improvement />} />
              <NavItem href="/cards" text={t('card')} icon={<FeedbachCard />} />
            </>
          </PopupMenu>
          { ['owner', 'member', 'supporter'].includes(currentRole) && (
            <PopupMenu text={t('sharedFeedbach')}>
              <>
                <NavItem href="/shared" text={t('sharedSurveys')} icon={<Survey />}/>
                <NavItem href="/shared-improvements" text={t('sharedGoals')} icon={<Improvement />} />
                <NavItem href="/shared-cards" text={t('sharedCards')} icon={<FeedbachCard />} />
                <NavItem href="/organization" text={t('orgDash')} icon={<OrgDash />} />
              </>
            </PopupMenu>
          )}
        </Box>
        <Box
          sx={{
            width: '256px',
            textAlign: 'right',
            ml: 'auto', // Pushes the box to the right
            bg: 'secondary', // Example background color
            // Add more styling as needed
          }}
        >
          <HamburgerMenu />
        </Box>
      </Section>
    </Box>
  );
};

export default HeadingSection;
