import { useApiData } from '../../contexts/ApiData';
import { useDateFormatter, useTranslations } from '../../contexts/Translation';
import { H2, H3 } from '../Headers';
import { InfoItem } from '../Survey/InfoItem';
import SystemMessage from '../SystemMessage/Index';
import { useEffect, useState } from 'react';
// @ts-ignore
// import { useScreenshot } from 'use-react-screenshot';

const CardStats = ({ card, isOpen }: any) => {
  const { get } = useApiData();
  const [stats, setStats] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(true);
  const d = useDateFormatter();
  const t = useTranslations('card');

  useEffect(() => {
    const call = async () => {
      const response = await get(isOpen ? `/c/${card.cardId}/stats`: `/card/${card.cardId}/stats`);
      setIsLoading(false);
      setStats(response);
    };
    call();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card.cardId]);

  if (isLoading) {
    return <SystemMessage message="loading" />
  }
  console.log(stats);

  return (
    <>
      <H2>{t('cardStatsHeading')}</H2>
      <InfoItem label={t('noOfSurveys')} value={stats.noOfSurveys} />
      <InfoItem label={t('noOfResponders')} value={card.responders} />
      <InfoItem label={t('uniqueResponders')} value={stats.uniqueResponders} />
      <InfoItem label={t('earliestResponse')} value={!stats.earliesResponse ? '-' : d(stats.earliesResponse)} />
      <InfoItem label={t('statsLatestResponse')} value={!stats.latestResponse ? '-' : d(stats.latestResponse)} />
      <H3>{t('responderDomains')}</H3>
      {stats.domains.map((domain: any) => <InfoItem key={domain.domain} label={domain.domain} value={domain.responders} />)}
    </>
  );
};

export default CardStats;
