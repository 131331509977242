import { useTranslations } from "../../contexts/Translation";
import { useEffect, useState } from 'react';
import { Box, Grid, Text } from "theme-ui";
import { useApiData } from "../../contexts/ApiData";
import { EmailLang, Responder, ValidationError } from "../../types";
import List from "../List/List";
import Input from "../Forms/Input";
import { H3 } from "../Headers";
import { useSurvey } from "../../contexts/Survey";
import Button from "../Button";
import LanguageSelector from "../LanguageSelector/Index";
import { useOrganizations } from "../../contexts/UserProvider";
import ErrorText from "../Forms/ErrorText";
import { Tip } from "../Tip/Index";
import { CheckMark, Waiting } from "../Icons";

type InvitesProps = {
  surveyId?: string;
  onInvitesUpdated?: (emails: EmailLang[]) => void; // Added prop
  displayInvitationText?: boolean;
  displayTip?: boolean;
  initialEmails?: EmailLang[];
};

const Invites = ({ surveyId, onInvitesUpdated, initialEmails = [], displayInvitationText = true, displayTip = true }: InvitesProps) => {
  const t = useTranslations('invites');
  const survey = useSurvey();
  const { post, get } = useApiData();
  const [responders, setResponders] = useState<Responder[]>([]);
  const [emails, setEmails] = useState<EmailLang[]>([...initialEmails, { email: '', lang: survey?.defaultLang || 'en' }]);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const { currentOrganization } = useOrganizations();
  const [maxEmails] = useState(!currentOrganization ? 10 : 1000);

  useEffect(() => {
    const call = async () => {
      if (surveyId) { // Ensure surveyId is defined
        try {
          const responderList = await get(`/survey/${surveyId}/responders`);
          setResponders(responderList);
        } catch (error) {
          console.error("Failed to fetch responders:", error);
          // Optionally, handle error state here
        }
      }
    }
    call();
  }, [get, surveyId]);

  const handleEmailChange = (index: number, value: string) => {
    setEmails(prevEmails => {
      const newEmails = [...prevEmails];
      newEmails[index] = {
        ...newEmails[index],
        email: value
      };

      // Add a new empty email field if necessary
      if (index === prevEmails.length - 1 && value && prevEmails.length < maxEmails) {
        newEmails.push({ email: '', lang: survey?.defaultLang || 'en' });
      }

      return newEmails;
    });
  };

  const handleLangChange = (index: number, value: string) => {
    setEmails(prevEmails => {
      const newEmails = [...prevEmails];
      newEmails[index] = {
        ...newEmails[index],
        lang: value
      };
      return newEmails;
    });
  };

  const isValidEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleBlur = (index: number) => {
    const { email } = emails[index];
    if (email && !isValidEmail(email)) {
      setErrors(prevErrors => {
        const hasError = prevErrors.some(e => e.name === `email-${index}`);
        if (!hasError) {
          return [...prevErrors, { name: `email-${index}`, message: t('invalidEmail') }];
        }
        return prevErrors;
      });
    } else {
      setErrors(prevErrors => prevErrors.filter(e => e.name !== `email-${index}`));
      onInvitesUpdated && onInvitesUpdated(emails.filter((e) => isValidEmail(e.email)));
    }

    // Remove empty email fields except the last one
    setEmails(prevEmails => prevEmails.filter((item, i) => item.email !== '' || i === prevEmails.length - 1));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validData = emails.filter(({ email }) => isValidEmail(email));
    
    if (validData.length > 0 && surveyId) {
      try {
        const result = await post(`/survey/${surveyId}/responders`, validData);
        if (result) {
          setResponders(prevResponders => [...prevResponders, ...result]);
          setEmails([{ email: '', lang: survey?.defaultLang || 'en' }]);
          setErrors([]);
        } else {
          setErrors([{ name: 'form', message: t('serverError') }]);
        }
      } catch (error) {
        console.error("Failed to submit responders:", error);
      }
    }
  };

  const maxExceeded = maxEmails < (emails.length + responders.length);

  const getStatusIcon = (status: string) => {
    if (status === 'responded') {
      return <Text sx={{ 'svg': { color: 'positive' } }}><CheckMark /></Text>;
    }
    return <Text sx={{ 'svg': { color: 'mediocre' } }}><Waiting /></Text>;
  };

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <List 
        items={responders.map((responder: Responder) => (
          <Box key={responder.email} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            {getStatusIcon(responder.status)}
            <Box sx={{ ml: 2 }}>
              {responder.email} - 
              <Text as="span" sx={{ color: responder.status === 'responded' ? 'positive' : 'mainText', ml: 1 }}>
                {t(responder.status)}
              </Text> - 
              {t(responder.lang)}
            </Box>
          </Box>
        ))} 
      />
      
      {emails.map(({ email, lang }, index) => (
        <Grid key={index} mb={3} sx={{
          alignItems: 'end',
          gridTemplateColumns: '5fr 1fr',
          gap: 16,
          mb: 16
        }}>
          <Box sx={{
            '> div': { mb: 0, pb: 0 },
            'input': { mb: 0 },
          }}>
            <Input
              error={errors.find(e => e.name === `email-${index}`)}
              type="email"
              name={`email-${index}`}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmailChange(index, e.target.value)}
              handleBlur={() => handleBlur(index)}
              label={t('emailLabel')}
              value={email}
            />
          </Box>
          <LanguageSelector
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleLangChange(index, e.target.value)}
            name={`lang-${index}`} // Updated name to be unique per index
            value={lang}
          />
        </Grid>
      ))}
      
      {maxExceeded && (
        <ErrorText sx={{ mb: 16 }} text={t(!currentOrganization ? 'maxEmailsExceededNoOrg' : 'maxEmailsExceeded')} />
      )}
      
      { displayTip && <Tip>{t('invitesInfo')}</Tip>}
      <ErrorText sx={{ mt: 32, fontSize: 24, }} error={errors.find(e => e.name === 'form')} />
      { !onInvitesUpdated && <Button type="submit" disabled={maxExceeded}>{t('submit')}</Button> }
      { displayInvitationText && (
        <>
          <H3>{t('invitationTextHeading')}</H3>
          <Box as="p" sx={{ whiteSpace: 'pre-wrap' }}>
            {survey?.invitationText || 'No invitation text available.'}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Invites;
