import { BaseThemeCompProp } from "../../types";
import { Box } from "theme-ui";

type SectionProps = BaseThemeCompProp & {
  id?: string;
  ref?: any;
}

const Section = ({ children, sx, ...props }: SectionProps) => (
  <Box as="section" sx={{
    maxWidth: 'container',
    m: '48px auto',
    px: [16, 16, 16, 0],
    ...sx,
  }}
  {...props}>
    {children}
  </Box>
);

export default Section;
