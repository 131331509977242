import { BaseThemeCompProp } from '../../types';
import { Link } from 'theme-ui';

type LinkProps = BaseThemeCompProp & {
  href: string;
}

const LinkContainer = ({ children, sx, href, ...props }: LinkProps) => (
  <Link sx={{
    textDecoration: 'none',
    color: 'linkColor',
    ':hover': { textDecoration: 'underline' },
    ...sx,
  }} href={href} {...props}>
    {children}
  </Link>
)

export default LinkContainer;
