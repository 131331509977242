import { Survey } from "../../types";
import { useApiData } from "../../contexts/ApiData";
import { useDateFormatter, useTranslations } from "../../contexts/Translation";
import { H2 } from "../Headers";
import Section from "../Section";
import SurveyShortView from "./ShortView";
import { Box } from "theme-ui";
import { useNavigate } from "react-router-dom";
import { Plus } from "../Icons";
import { useEffect, useState } from "react";
import SystemMessage from '../SystemMessage/Index';
import { InfoMessage } from "../InfoMessage/Index";
import { useOrganizations } from "../../contexts/UserProvider";

type SurveysProps = {
  limitFinished?: number;
  isShared?: boolean;
};

const Surveys = ({ isShared = false, limitFinished = -1}: SurveysProps) => {
  const t = useTranslations('surveys');
  const d = useDateFormatter();
  const { get } = useApiData();
  const navigate = useNavigate();
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { currentOrganization } = useOrganizations();

  useEffect(() => {
    const callOwnSurveys = async () => {
      const surveyList = await get('/survey');
      setSurveys(surveyList);
      setIsLoading(false);
    }
    const callSharedSurveys = async () => {
      const surveys = await get(`/shared-survey`);
      setSurveys(surveys);
      setIsLoading(false);
    }
    if (isShared) {
      callSharedSurveys();
    } else {
      callOwnSurveys();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSurveyShortTitle = ({ surveyName, status, endDate }: Survey) => {
    if (surveyName) {
      if (status === 'finished') {
        return t('titleNameFinished', surveyName, d(endDate));
      }
      return t('titleNameOngoing', surveyName, d(endDate));
    } else {
      if (status === 'finished') {
        return t('titleFinished', d(endDate));
      }
      return t('titleOngoing', d(endDate));
    }
  };

  const listSurveys = (list: Survey[]) => list.map(s  => {
    return (
      <SurveyShortView
        isShared={isShared}
        sx={{
          borderWidth: '3px',
          borderRadius: '10px',
          borderStyle: 'solid',
          borderColor: 'muted',
        }}
        key={s.id}
        title={getSurveyShortTitle(s)}
        {...s} />
    );
  });

  const ongoingSurveys = surveys.filter((s: Survey) => s.status === 'ongoing');
  const finishedSurveys = surveys.filter((s: Survey) => s.status !== 'ongoing').filter((s, i) => {
    if (limitFinished > 0 && limitFinished <= i) {
      return false;
    }
    return s.status === 'finished'
  });
  
  if (isLoading) {
    return <SystemMessage message="loading" />
  }

  return (
    <Section>
      <H2>{t(isShared ? 'sharedHeading': 'title')}</H2>
      { surveys.length === 0 && <InfoMessage>{t(isShared ? 'noSharedSurveys' : 'noSurveys')}</InfoMessage>}
      { listSurveys(ongoingSurveys) }
      { !isShared && (
        !currentOrganization && surveys.length > 0 ? <InfoMessage>{t('onlyOneSurvey')}</InfoMessage> :
        <Box
          sx={{
            borderWidth: '3px',
            borderRadius: '10px',
            borderStyle: 'dashed',
            borderColor: 'muted',
            color: 'mutedText',
            py: 32,
            px: 32,
            m: 0,
            mb: 16,
            cursor: 'pointer',
            textAlign: 'center',
            fontSize: 32,
            fontFamily: 'interaction',
          }}
          onClick={() => navigate(`/survey`)}>
          <Plus width='64px' height='64px' /> {t('createSurvey')}
        </Box>) }
      { listSurveys(finishedSurveys) }
    </Section>
  );
};

export default Surveys;
