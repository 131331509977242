import { Box } from "theme-ui";
import { useTranslations } from "../../contexts/Translation";
import { Plus } from "../Icons";
import { useNavigate } from "react-router-dom";

type ComponentProps = {
  isShared?: boolean;
  surveyId: string;
  text?: string;
}

export const CreateImprovementGoalLink = ({ surveyId, isShared, text }: ComponentProps) => {
  const t = useTranslations('improvementGoals');
  const navigate = useNavigate();

  if (isShared) {
    return null;
  }
  return (
    <Box sx={{ mt: 32 }}>
      <Box
        sx={{
          borderWidth: '3px',
          borderRadius: '10px',
          borderStyle: 'dashed',
          borderColor: 'muted',
          color: 'mutedText',
          py: 32,
          px: 32,
          m: 0,
          mb: 16,
          cursor: 'pointer',
          textAlign: 'center',
          fontSize: 32,
          fontFamily: 'interaction',
        }}
        onClick={() => { navigate(`/improvement?fromSurvey=${surveyId}`); window.scrollTo(0, 0); }}>
        <Plus width='64px' height='64px' /> {text || t('create')}
      </Box>
    </Box>
  );
};
